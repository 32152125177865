const translations = {
    aam: 'Application Maps',
    account: 'My Account',
    copyright: 'Copyright © {{year}} by Rantizo',
    createProductUsageReport: 'Create New Report',
    createWorkOrder: 'Create New Work Order',
    dashboard: 'Dashboard',
    faaReports: 'FAA Reports',
    flightLog: 'Flight Log',
    flyForRantizo: 'Fly for Rantizo',
    logout: 'Log Out',
    manageConnections: 'Manage Connections',
    manageOrganization: 'Manage Organization',
    myOrganization: 'My Organization',
    productUsageReports: 'Product Usage Reports',
    rantizoShop: 'Rantizo Shop',
    serviceOperatorMap: 'Service Provider Map',
    termsOfService: 'Privacy Policy and Terms of Service',
    workOrders: 'Work Orders'
};

export default translations;
