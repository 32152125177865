import { forwardRef } from 'react';

import { TextInput } from '@rantizo-software/rantizo-ui';

import type { Props } from './types';

const CompanyNameInput = forwardRef<HTMLInputElement, Props>((props, ref) => {
    const {
        className = '',
        hasError,
        isDisabled = false,
        isEditable = true,
        isRequired = false,
        name = 'companyName',
        onChange,
        onError,
        onKeyUp,
        onSubmit,
        onValid,
        placeholder = '',
        value
    } = props;

    return (
        <TextInput
            className={className}
            hasError={hasError}
            isDisabled={isDisabled}
            isEditable={isEditable}
            isRequired={isRequired}
            name={name}
            onChange={onChange}
            onError={onError}
            onKeyUp={onKeyUp}
            onSubmit={onSubmit}
            onValid={onValid}
            placeholder={placeholder}
            ref={ref}
            value={value}
        />
    );
});

CompanyNameInput.displayName = 'CompanyNameInput';

export default CompanyNameInput;
