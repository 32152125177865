import useTranslation from '../useTranslation';

import { normalizeOrganizationRequest } from '@@utils/data';

import useOrganizations from 'hooks/useOrganizations';

import type { OrganizationRequest } from './types';

const useCreateOrganization = () => {
    const { createOrganization } = useOrganizations();
    const { API_ERROR } = useTranslation();

    return async (companyName: string) => {
        const organizationData: OrganizationRequest = {
            companyName: companyName,
            location: {
                address1: '',
                city: '',
                state: '',
                zipCode: ''
            }
        };

        try {
            const result = await createOrganization(normalizeOrganizationRequest(organizationData));

            return result?.data;
        } catch (error: unknown) {
            console.error(error);
            alert(API_ERROR);
        }
    };
};

export default useCreateOrganization;
