import { useCallback, useState } from 'react';

import useFetch from 'hooks/useFetch';
import {
    FIELD_VIEW_AUTH_URL,
    FIELD_VIEW_CLIENT_ID,
    FIELD_VIEW_CONNECTION_API,
    FIELD_VIEW_REDIRECT_URI,
    FIELD_VIEW_SCOPES
} from 'hooks/useFieldView/constants';
import type {
    CreateFieldviewConnection,
    DeleteFieldViewConnection,
    GetFieldViewConnectionStatus,
    OAuth2Params,
    OAuth2Token
} from 'hooks/useFieldView/types';
import useGlobalStore from 'hooks/useGlobalStore';
import useOAuth2 from 'hooks/useOAuth2';
import useToast from 'hooks/useToast';

const useFieldView = () => {
    const { getValue, setValue } = useGlobalStore({ namespace: 'useFieldView' });
    const [isLoading, setIsLoading] = useState(false);
    const [isFieldViewConnected, setIsFieldViewConnected] = useState<boolean | undefined>(
        undefined
    );

    const { broadcastToast } = useToast();

    const saveFieldViewToken = setValue('fieldViewToken');

    const token = getValue('fieldViewToken') as OAuth2Token | undefined;

    const { authenticatedDelete, authenticatedGet, authenticatedPost } = useFetch();

    const oAuth2Params = {
        authorizationEndpoint: FIELD_VIEW_AUTH_URL,
        clientId: FIELD_VIEW_CLIENT_ID,
        redirectUri: FIELD_VIEW_REDIRECT_URI,
        scope: FIELD_VIEW_SCOPES,
        setToken: saveFieldViewToken,
        token: token,
        tokenEndpoint: ''
    } as OAuth2Params;

    const { fetchTokenOrRedirectAndLogIn } = useOAuth2(oAuth2Params);

    const createFieldviewConnection: CreateFieldviewConnection = useCallback(
        async (code: string) => {
            setIsLoading(true);

            try {
                const { data } = await authenticatedPost(
                    FIELD_VIEW_CONNECTION_API,
                    JSON.stringify({
                        code,
                        redirectUri: FIELD_VIEW_REDIRECT_URI
                    })
                );

                setIsFieldViewConnected(true);
                setIsLoading(false);

                return data;
            } catch (error) {
                setIsLoading(false);

                broadcastToast({
                    text: JSON.stringify(error),
                    type: 'error'
                });
            } finally {
                setIsLoading(false);
            }
        },
        [authenticatedPost, broadcastToast]
    );

    const getFieldViewConnectionStatus: GetFieldViewConnectionStatus = useCallback(async () => {
        try {
            setIsLoading(true);
            const { data } = await authenticatedGet(FIELD_VIEW_CONNECTION_API);

            setIsFieldViewConnected(data.enabled);
            setIsLoading(false);
        } catch (error) {
            broadcastToast({
                text: JSON.stringify(error),
                type: 'error'
            });
        } finally {
            setIsLoading(false);
        }
    }, [authenticatedGet, broadcastToast]);

    const deleteFieldViewConnection: DeleteFieldViewConnection = useCallback(async () => {
        try {
            setIsLoading(true);
            await authenticatedDelete(FIELD_VIEW_CONNECTION_API, JSON.stringify({}));
            setIsFieldViewConnected(false);
            setIsLoading(false);
        } catch (error) {
            broadcastToast({
                text: JSON.stringify(error),
                type: 'error'
            });
        } finally {
            setIsLoading(false);
        }
    }, [authenticatedDelete, broadcastToast]);

    return {
        createFieldviewConnection,
        deleteFieldViewConnection,
        fetchTokenOrRedirectAndLogIn,
        getFieldViewConnectionStatus,
        isFieldViewConnected,
        isLoading
    };
};

export default useFieldView;
