import { forwardRef, useCallback, useEffect } from 'react';

import { Dropdown, WithErrorMessage } from '@rantizo-software/rantizo-ui';

import useFormError from 'hooks/useFormError';

import useData from './hooks/useData';

import { TEST_ID } from './constants';
import type { OnError, Props } from './types';

const ProductUsageReportDropdown = forwardRef<HTMLSelectElement, Props>(
    (
        {
            className = '',
            hasError = false,
            isRequired = false,
            label = '',
            onChange,
            onError,
            onSubmit,
            onValid,
            placeholder = '',
            selected,
            testId = TEST_ID
        },
        ref
    ) => {
        const { fetchDropdownItems, productUsageReportItems } = useData();
        const { clearError, errorMessage, handleError } = useFormError(onError);

        useEffect(() => {
            fetchDropdownItems();
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []);

        const handleChange = useCallback(
            (_selectedValue: unknown, _handleError: OnError, index: number) => {
                clearError();
                onChange?.(productUsageReportItems[index].value);
            },
            [onChange, productUsageReportItems, clearError]
        );

        const hasAnError = Boolean(hasError || errorMessage);

        return (
            <WithErrorMessage className={className} text={errorMessage}>
                <Dropdown
                    className={className}
                    hasError={hasAnError}
                    isDisabled={productUsageReportItems.length === 0}
                    isRequired={isRequired}
                    items={productUsageReportItems}
                    label={label}
                    onChange={handleChange}
                    onError={handleError}
                    onSubmit={onSubmit}
                    onValid={onValid}
                    placeholder={placeholder}
                    ref={ref}
                    selected={selected}
                    testId={testId}
                />
            </WithErrorMessage>
        );
    }
);

ProductUsageReportDropdown.displayName = 'ProductUsageReportDropdown';

export default ProductUsageReportDropdown;
