import { useCallback } from 'react';

import useFetch from 'hooks/useFetch';

import { MAPBOX_GEOCODING_API_URL, MAPBOX_REVERSE_GEOCODING_API_URL } from './constants';
import type { Location, MapBoxFeature } from './types';

const useMapboxGeocoding = () => {
    const { httpGet } = useFetch();

    const fetchMapboxGeocoding = useCallback(
        async (searchText: string) => {
            const text = `${encodeURIComponent(searchText)}.json`;

            const options = `?access_token=${
                import.meta.env.VITE_MAPBOX_TOKEN
            }&autocomplete=false&fuzzyMatch=false`;

            const url = `${MAPBOX_GEOCODING_API_URL}${text}${options}`;

            const response = await httpGet(url);

            return response;
        },
        [httpGet]
    );

    const fetchAddressFromCoordinates = useCallback(
        async (latitude: number, longitude: number) => {
            const url = MAPBOX_REVERSE_GEOCODING_API_URL(
                latitude,
                longitude,
                import.meta.env.VITE_MAPBOX_TOKEN
            );

            const { data, error } = await httpGet(url);

            if (error) {
                console.error(error);
                throw new Error(JSON.stringify(error));
            }

            const { features } = data;

            if (features.length > 0) {
                const firstResult = features[0];
                const { properties } = firstResult;
                const { district, place, postcode, region } = properties.context;
                const city = place.name;
                const zipCode = postcode.name;
                const county = district.name;
                const state = region.name;

                return `${city}, ${state}, ${zipCode}, ${county}`;
            }

            return '';
        },
        [httpGet]
    );

    const getMapboxFeatures = useCallback(
        async (location: Location): Promise<MapBoxFeature[]> => {
            const { address1 = '', address2 = '', city = '', state = '', zipCode = '' } = location;

            if (!address1 || !city || !state || !zipCode) {
                return [];
            }

            const address = `${address1} ${address2 ?? ''}, ${city}, ${state} ${zipCode}`;
            const response = await fetchMapboxGeocoding(address);

            if (response.error) {
                return [];
            }

            if (response.data) {
                const { data } = response;

                return data.features as MapBoxFeature[];
            }

            return [];
        },
        [fetchMapboxGeocoding]
    );

    const getAddressLocation = useCallback(
        async (location: Location): Promise<number[] | null> => {
            const mapboxFeatures = await getMapboxFeatures(location);

            if (mapboxFeatures.length > 0) {
                return mapboxFeatures[0].center;
            }

            return null;
        },
        [getMapboxFeatures]
    );

    const validateAddress = useCallback(
        async (location: Location) => {
            const mapboxFeatures = await getMapboxFeatures(location);

            return mapboxFeatures.some(feature => feature.relevance >= 0.85);
        },
        [getMapboxFeatures]
    );

    return {
        fetchAddressFromCoordinates,
        fetchMapboxGeocoding,
        getAddressLocation,
        validateAddress
    };
};

export default useMapboxGeocoding;
