import {
    Table,
    TableBody,
    TableBodyCell,
    TableBodyRow,
    useTable
} from '@rantizo-software/rantizo-ui';

import useColumns from './hooks/useColumns';

import { TEST_ID } from './constants';
import type { Props } from './types';

import styles from './styles.module.scss';

const JohnDeereOrganizationsListTable = ({ data, testId = TEST_ID }: Props) => {
    const columns = useColumns();

    const { rows } = useTable({
        columns,
        data: data
    });

    return (
        <Table className={styles.table} hideScrollbar={true} testId={testId}>
            <TableBody>
                {rows.map(row => (
                    <TableBodyRow className={styles.tableGrid} key={row.id}>
                        {row.getVisibleCells?.().map(cell => (
                            <TableBodyCell className={styles.tableBodyCell} key={cell.id}>
                                {cell.value}
                            </TableBodyCell>
                        ))}
                    </TableBodyRow>
                ))}
            </TableBody>
        </Table>
    );
};

export default JohnDeereOrganizationsListTable;
