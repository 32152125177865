import { normalizeOrganizationRequest } from '@@utils/data';

import useCurrentOrganization from 'hooks/useCurrentOrganization';

import type { OrganizationRequest } from './types';

const useData = () => {
    const { fetchCurrentOrganization, updateCurrentOrganization } = useCurrentOrganization();

    const saveToServer = async (values: OrganizationRequest) => {
        const result = await updateCurrentOrganization(normalizeOrganizationRequest(values));

        if (result?.data) {
            await fetchCurrentOrganization();
        }

        return result;
    };

    return {
        saveToServer
    };
};

export default useData;
