import { useMemo } from 'react';

import { ItemLabel, VerticalContainer } from '@rantizo-software/rantizo-ui';

import WorkOrderReadOnlyText from 'components/WorkOrderReadOnlyText';

import useConvert from 'hooks/useConvert';

import type { FunctionComponent, Props } from './types';

import styles from './styles.module.scss';

const DryChemicalAppliedView: FunctionComponent<Props> = ({ chemicalApplied }) => {
    const {
        convertKilogramsPerSquareMeterToPreferredUnits,
        convertKilogramsToPreferredUnits,
        convertPreferredRateToLabel,
        massLabels
    } = useConvert();

    const {
        chemical,
        massAppliedKilograms,
        preferredMassUnits,
        preferredRateUnits,
        rateAppliedKilogramPerSquareMeter
    } = chemicalApplied;

    const mass = useMemo(
        () =>
            `${convertKilogramsToPreferredUnits(massAppliedKilograms, preferredMassUnits)} ${massLabels[preferredMassUnits]}`,
        [convertKilogramsToPreferredUnits, massAppliedKilograms, massLabels, preferredMassUnits]
    );

    const rate = useMemo(
        () =>
            `${convertKilogramsPerSquareMeterToPreferredUnits(rateAppliedKilogramPerSquareMeter, preferredRateUnits)} ${convertPreferredRateToLabel(preferredRateUnits)}`,
        [
            convertKilogramsPerSquareMeterToPreferredUnits,
            convertPreferredRateToLabel,
            preferredRateUnits,
            rateAppliedKilogramPerSquareMeter
        ]
    );

    return (
        <VerticalContainer className={styles.dryChemicalAppliedView}>
            <ItemLabel text={chemical.labelName ? chemical.labelName : ''} />

            <WorkOrderReadOnlyText text={mass} />

            <WorkOrderReadOnlyText text={rate} />
        </VerticalContainer>
    );
};

export default DryChemicalAppliedView;
