import { Field } from 'formik';
import { states } from 'types/states';

import CityStateZipContainer from 'components/CityStateZipContainer';
import ErrorMessage from 'components/ErrorMessage';
import FormSectionContainer from 'components/FormSectionContainer';
import VerticalContainerNoGap from 'components/VerticalContainerNoGap';
import FormInput from 'components/deprecating/FormInput';
import FormSelect from 'components/deprecating/FormSelect';
import ZipCodeInput from 'components/deprecating/ZipCodeInput';

import useTranslation from './hooks/useTranslation';

import { TEST_ID } from './constants';
import type { FunctionComponent, Props } from './types';

/**
 * @deprecated Use `src/components/form/AddressForm` instead.
 */
const AddressFields: FunctionComponent<Props> = ({
    className = '',
    isDisabled,
    isEditable = true,
    namePrefix = '',
    testId = TEST_ID
}) => {
    const { ADDRESS_1, CITY, OPTIONAL, STATE, ZIP_CODE } = useTranslation();

    return (
        <FormSectionContainer className={className} testId={testId}>
            <VerticalContainerNoGap>
                <Field
                    component={FormInput}
                    disabled={isDisabled}
                    isEditable={isEditable}
                    label={ADDRESS_1}
                    name={`${namePrefix}location.address1`}
                    placeholder={OPTIONAL}
                    testId={`${namePrefix}location.address1`}
                    type="text"
                />

                <Field
                    component={FormInput}
                    disabled={isDisabled}
                    isEditable={isEditable}
                    name={`${namePrefix}location.address2`}
                    placeholder={OPTIONAL}
                    testId={`${namePrefix}location.address2`}
                    type="text"
                />
            </VerticalContainerNoGap>

            <CityStateZipContainer>
                <Field
                    component={FormInput}
                    disabled={isDisabled}
                    isEditable={isEditable}
                    label={CITY}
                    name={`${namePrefix}location.city`}
                    placeholder={OPTIONAL}
                    testId={`${namePrefix}location.city`}
                    type="text"
                />

                <Field
                    component={FormSelect}
                    data={states}
                    disabled={isDisabled}
                    isEditable={isEditable}
                    label={STATE}
                    name={`${namePrefix}location.state`}
                    placeholder={OPTIONAL}
                    searchable
                    selectOnBlur
                    testId={`${namePrefix}location.state`}
                />

                <Field
                    component={ZipCodeInput}
                    disabled={isDisabled}
                    isEditable={isEditable}
                    label={ZIP_CODE}
                    name={`${namePrefix}location.zipCode`}
                    placeholder={OPTIONAL}
                    testId={`${namePrefix}location.zipCode`}
                    type="text"
                />
            </CityStateZipContainer>

            <ErrorMessage name={`${namePrefix}location`} />
        </FormSectionContainer>
    );
};

export default AddressFields;
