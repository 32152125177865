import useGlobalStore from 'hooks/useGlobalStore';

import { TIME } from './constants';
import type { Notification, NotificationSetter } from './types';

export const useToast = () => {
    const { getValue, setValue } = useGlobalStore({ namespace: 'useToast' });

    const toastData = () => getValue('toast') as Notification | null;
    const setToastData = setValue('toast') as NotificationSetter;

    const broadcastToast = ({ text, time = TIME, type }: Notification & { time?: number }) => {
        setToastData({ text, type });
        setTimeout(() => setToastData(null), time);
    };

    return { broadcastToast, setToastData, toastData };
};

export default useToast;
