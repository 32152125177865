import { INPUT_CHARS_MAX } from 'config';

import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('companyDetailsForm');

    return {
        ERRORS: {
            ADDRESS_2_MAX: t('errors.address2Max', { charMax: INPUT_CHARS_MAX }),
            ADDRESS_MAX: t('errors.addressMax', { charMax: INPUT_CHARS_MAX }),
            ADDRESS_REQUIRED: t('errors.addressRequired'),
            CITY_MAX: t('errors.cityMax', { charMax: INPUT_CHARS_MAX }),
            CITY_REQUIRED: t('errors.cityRequired'),
            LOCATION_INVALID: t('errors.locationInvalid'),
            NAME_MAX: t('errors.nameMax', { charMax: INPUT_CHARS_MAX }),
            NAME_REQUIRED: t('errors.nameRequired'),
            PHONE_INVALID: t('errors.phoneInvalid'),
            PHONE_REQUIRED: t('errors.phoneRequired'),
            STATE_REQUIRED: t('errors.stateRequired'),
            ZIP_CODE_REQUIRED: t('errors.zipCodeRequired')
        },
        PLACEHOLDERS: {
            NAME: t('placeholders.name'),
            OPTIONAL: t('placeholders.optional'),
            PHONE: t('placeholders.phone')
        }
    };
};

export default useTranslation;
