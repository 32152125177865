import { useCallback } from 'react';
import { generatePagedQuery } from 'utils/string.ts';

import useFetch from 'hooks/useFetch';
import useGlobalStore from 'hooks/useGlobalStore/index.ts';

import { AIRCRAFT_API } from './constants.ts';
import type { PagedResponseDroneResponse } from './types';

const useAircraft = () => {
    const { authenticatedDelete, authenticatedGet } = useFetch();
    const { setValue } = useGlobalStore({ namespace: 'useAircraft' });

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const saveAircraftsToStore = useCallback(setValue('aircrafts'), []);

    const fetchAircraft = useCallback(
        async (id: string) => {
            const uri = `${AIRCRAFT_API}/${id}`;

            return await authenticatedGet(uri, null);
        },
        [authenticatedGet]
    );

    const fetchAircrafts = useCallback(
        async (pageToken?: string, pageSize = 10, hasFlightControllerSerial: boolean = false) => {
            const queryParams = generatePagedQuery(pageToken, pageSize);

            let uri = `${AIRCRAFT_API}?${queryParams.toString()}`;

            if (hasFlightControllerSerial) {
                uri += '&hasFlightControllerSerial=true';
            }

            const response = await authenticatedGet(uri, null);

            if (response.error) {
                throw new Error(JSON.stringify(response.error));
            }

            return response.data as PagedResponseDroneResponse;
        },
        [authenticatedGet]
    );

    const fetchAllAircrafts = useCallback(
        async (hasFlightControllerSerial: boolean = false) => {
            try {
                let aircraftResponse = await fetchAircrafts(
                    undefined,
                    10,
                    hasFlightControllerSerial
                );
                let aircraft = aircraftResponse.objects;
                let pageToken = aircraftResponse.nextPageToken;

                while (aircraftResponse.hasNext) {
                    aircraftResponse = await fetchAircrafts(
                        pageToken,
                        10,
                        hasFlightControllerSerial
                    );

                    aircraft = aircraft.concat(aircraftResponse.objects);
                    pageToken = aircraftResponse.nextPageToken;
                }

                saveAircraftsToStore(aircraft);

                return {
                    data: aircraft,
                    error: undefined
                };
            } catch (e) {
                return {
                    data: undefined,
                    error: e
                };
            }
        },
        [fetchAircrafts, saveAircraftsToStore]
    );

    const deleteAircraft = useCallback(
        async (id: string) => {
            const uri = `${AIRCRAFT_API}/${id}`;

            const response = await authenticatedDelete(uri, null);

            if (response.error) {
                throw new Error(JSON.stringify(response.error));
            }
        },
        [authenticatedDelete]
    );

    return {
        deleteAircraft,
        fetchAircraft,
        fetchAircrafts,
        fetchAllAircrafts
    };
};

export default useAircraft;
