import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('verifyOrganizationPage');

    return {
        API_ERROR: t('apiError'),
        CREATE_ORGANIZATION: t('createOrganization'),
        ERROR: t('error'),
        ORGANIZATION: t('organization'),
        SELECT_ORGANIZATION: t('selectOrganization'),
        TITLE: t('title')
    };
};

export default useTranslation;
