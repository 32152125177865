import { useCallback, useState } from 'react';

import useFetch from 'hooks/useFetch';

import { MAP_API } from './constants';
import type { ExportMap } from './types';

const useJohnDeereMapLayers = () => {
    const [isLoading, setIsLoading] = useState(false);
    const { authenticatedPost, handleLoadingAndErrors } = useFetch();

    const exportMap: ExportMap = useCallback(
        async mapRequest => {
            const callback = async () => {
                const { data } = await authenticatedPost(MAP_API, JSON.stringify(mapRequest));

                setIsLoading(false);

                return data.redirectLink;
            };

            const { data } = await handleLoadingAndErrors({
                callback,
                setIsLoading
            });

            return data;
        },
        [authenticatedPost, handleLoadingAndErrors]
    );

    return {
        exportMap,
        isLoading
    };
};

export default useJohnDeereMapLayers;
