import { useMemo } from 'react';

import { VerticalContainer, useDate } from '@rantizo-software/rantizo-ui';

import DatedListSeparatorTitle from 'components/DatedListSeparatorTitle';

import { TEST_ID } from './constants';
import type { FunctionComponent, Props } from './types';

import styles from './styles.module.scss';

const DatedList: FunctionComponent<Props> = ({
    className = '',
    dateFormat = 'FULL',
    items,
    sortOrder = 'DESC',
    testId = TEST_ID
}) => {
    const { formatDateSeparator, formatMonthYearSeparator } = useDate();

    const dateFormats = useMemo(
        () => ({
            FULL: (date: string) => formatDateSeparator(date),
            MONTH_YEAR_ONLY: (date: string) => formatMonthYearSeparator(date)
        }),
        [formatDateSeparator, formatMonthYearSeparator]
    );

    const sortedDateKeys = useMemo(
        () =>
            Object.keys(items).sort(
                (a, b) =>
                    new Date(sortOrder === 'DESC' ? b : a).getTime() -
                    new Date(sortOrder === 'DESC' ? a : b).getTime()
            ),
        [items, sortOrder]
    );

    return sortedDateKeys.map(date => {
        const datedItems = items[date];
        const dateSeparator = dateFormats[dateFormat](date);

        return (
            <VerticalContainer className={`${styles.verticalContainer} ${className}`} key={date}>
                <DatedListSeparatorTitle text={dateSeparator} />

                <VerticalContainer className={styles.datedItems} testId={testId}>
                    {datedItems}
                </VerticalContainer>
            </VerticalContainer>
        );
    });
};

export default DatedList;
