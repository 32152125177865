import { type MutableRefObject, useCallback, useEffect, useRef, useState } from 'react';

import { DropdownMenu, EllipsisButton, useAnchorPosition } from '@rantizo-software/rantizo-ui';

import useEvent from 'hooks/useEvent';

import { TEST_ID } from './constants';
import type { FunctionComponent, Props } from './types';

import styles from './styles.module.scss';

const OptionsButton: FunctionComponent<Props> = ({
    children,
    className = '',
    testId = TEST_ID
}) => {
    const [isOpen, setIsOpen] = useState(false);

    const containerRef = useRef<HTMLDivElement | null>(null);
    const menuRef = useRef<HTMLDivElement>(null);

    const { positionElement } = useAnchorPosition({ anchorRef: containerRef, menuRef });

    const handleClickOutside = useCallback(
        (event: Event) => {
            const isMenuItem = (event.target as HTMLDivElement).closest(
                `[data-testid="${testId}"]`
            );

            if (!isMenuItem) {
                setIsOpen(false);
            }
        },
        [testId]
    );

    useEvent({ event: 'mousedown', handler: handleClickOutside });

    useEffect(() => {
        if (isOpen) {
            positionElement();
        }
    }, [isOpen, positionElement]);

    const handleClose = useCallback(() => {
        setIsOpen(false);
    }, []);

    const handleToggle = useCallback(() => {
        setIsOpen(currentState => !currentState);
    }, []);

    return (
        <div className={`${className} ${styles.optionsButton}`} data-testid={testId}>
            <EllipsisButton
                onClick={handleToggle}
                ref={containerRef as MutableRefObject<HTMLButtonElement | null>}
            />

            {isOpen && (
                <DropdownMenu className={styles.dropdownMenu} onClose={handleClose} ref={menuRef}>
                    {children({ closeMenu: handleClose })}
                </DropdownMenu>
            )}
        </div>
    );
};

OptionsButton.displayName = 'OptionsButton';

export default OptionsButton;
