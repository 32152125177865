export const TEST_ID = 'johnDeereConnectionListItem';

export const JOHN_DEERE = 'johnDeere';

export const MANAGE_CONNECTIONS_PAGE = '/connections';

export const CODE = 'code';

export const SOURCE = 'source';

export const UNDEFINED = 'undefined';
