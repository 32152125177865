import type { Coordinate } from 'generatedTypes/user/models';
import { useCallback, useMemo, useState } from 'react';

import useCurrentOrganization from 'hooks/useCurrentOrganization';

const useLocation = () => {
    const { currentOrganization } = useCurrentOrganization();

    const defaultCoordinates = useMemo(
        () =>
            currentOrganization?.location?.coordinates
                ? currentOrganization?.location.coordinates
                : [-91.5703988, 41.6298025],
        [currentOrganization]
    );

    const [currentLocation, setCurrentLocation] = useState<number[] | Coordinate[]>(
        defaultCoordinates
    );

    const fetchCurrentLocation = useCallback(() => {
        if ('geolocation' in navigator) {
            navigator.geolocation.getCurrentPosition(position =>
                setCurrentLocation([position.coords.longitude, position.coords.latitude])
            );
        } else {
            // if we can't then we'll set it to the default.
            setCurrentLocation(defaultCoordinates);
        }
    }, [setCurrentLocation, defaultCoordinates]);

    return {
        currentLocation,
        fetchCurrentLocation
    };
};

export default useLocation;
