import { memo, useCallback, useEffect, useMemo } from 'react';

import { WithErrorMessage, useValidation } from '@rantizo-software/rantizo-ui';

import JohnDeereOrganizationsDropdown from 'components/form/JohnDeereOrganizationsDropdown';

import useTranslation from './hooks/useTranslation';

import type { Props, Validator } from './types';

const JohnDeereOrganizationsDropdownField = <T extends object>(props: Props<T>) => {
    const {
        authorizedOrganizationsOnly,
        form,
        isDisabled = false,
        isEditable = true,
        isRequired = false,
        name
    } = props;
    const { getFieldError, getValues, handleChange, handleError, handleValid, register } = form;

    const { ref } = register<HTMLInputElement>(name);

    const { REQUIRED_ERROR_MESSAGE } = useTranslation();

    const { validateRequired } = useValidation();

    const validationMap: [Validator, string][] = useMemo(
        () => [[validateRequired(isRequired), REQUIRED_ERROR_MESSAGE]],
        [REQUIRED_ERROR_MESSAGE, isRequired, validateRequired]
    );

    const validateValue = useCallback(
        (value: string) => {
            let validationErrorMessage = '';

            const isValid = validationMap.every(([validator, text]) => {
                validationErrorMessage = text;

                return validator(value);
            });

            if (!isValid) {
                handleError(name, { message: validationErrorMessage });
            }

            return isValid;
        },
        [handleError, name, validationMap]
    );

    const handleFieldChange = (value: string) => {
        if (!name) {
            return;
        }

        handleChange(name, value);

        if (validateValue(value)) {
            handleValid(name);
        }
    };

    useEffect(() => {
        validateValue(ref.current.value);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fieldErrors = getFieldError(name);

    return (
        <WithErrorMessage text={fieldErrors ? fieldErrors[0].message : ''}>
            <JohnDeereOrganizationsDropdown
                authorizedOrganizationsOnly={authorizedOrganizationsOnly}
                hasError={!!fieldErrors}
                isDisabled={isDisabled}
                isEditable={isEditable}
                onChange={handleFieldChange}
                onError={(message?: string) => handleError(name, { message })}
                ref={ref}
                selectedOrganization={getValues(name) as string}
            />
        </WithErrorMessage>
    );
};

JohnDeereOrganizationsDropdownField.displayName = 'JohnDeereOrganizationsDropdownField';

export default memo(
    JohnDeereOrganizationsDropdownField,
    (prevProps, nextProps) =>
        prevProps.name === nextProps.name &&
        prevProps.form.formErrors === nextProps.form.formErrors &&
        prevProps.isEditable === nextProps.isEditable
) as unknown as typeof JohnDeereOrganizationsDropdownField;
