import { useCallback, useState } from 'react';

import useFetch from 'hooks/useFetch';
import useGlobalStore from 'hooks/useGlobalStore';
import useOAuth2 from 'hooks/useOAuth2';

import {
    JOHN_DEERE_AUTH_URL,
    JOHN_DEERE_CLIENT_ID,
    JOHN_DEERE_CONNECTION_API,
    JOHN_DEERE_REDIRECT_URI,
    JOHN_DEERE_SCOPES
} from './constants';
import type {
    CreateJohnDeereConnection,
    DeleteJohnDeereConnection,
    GetJohnDeereConnectionStatus,
    OAuth2Params,
    OAuth2Token
} from './types';

const useJohnDeereConnection = () => {
    const { getValue, setValue } = useGlobalStore({ namespace: 'useJohnDeere' });
    const [isLoading, setIsLoading] = useState(false);
    const [isJohnDeereConnected, setIsJohnDeereConnected] = useState<boolean | undefined>(
        undefined
    );

    const saveJohnDeereToken = setValue('johnDeereToken');

    const token = getValue('johnDeereToken') as OAuth2Token | undefined;

    const { authenticatedDelete, authenticatedGet, authenticatedPost, handleLoadingAndErrors } =
        useFetch();

    const oAuth2Params = {
        authorizationEndpoint: JOHN_DEERE_AUTH_URL,
        clientId: JOHN_DEERE_CLIENT_ID,
        redirectUri: JOHN_DEERE_REDIRECT_URI,
        scope: JOHN_DEERE_SCOPES,
        setToken: saveJohnDeereToken,
        token: token,
        tokenEndpoint: ''
    } as OAuth2Params;

    const { codeVerifier, fetchTokenOrRedirectAndLogIn } = useOAuth2(oAuth2Params);

    const createJohnDeereConnection: CreateJohnDeereConnection = useCallback(
        async (code: string) => {
            const callback = async () => {
                await authenticatedPost(
                    JOHN_DEERE_CONNECTION_API,
                    JSON.stringify({
                        code,
                        codeVerifier,
                        redirectUri: JOHN_DEERE_REDIRECT_URI
                    })
                );

                setIsJohnDeereConnected(true);
            };

            const data = await handleLoadingAndErrors({
                callback,
                setIsLoading
            });

            return data;
        },
        [authenticatedPost, codeVerifier, handleLoadingAndErrors]
    );

    const getJohnDeereConnectionStatus: GetJohnDeereConnectionStatus = useCallback(async () => {
        setIsLoading(true);

        const callback = async () => {
            const { data } = await authenticatedGet(JOHN_DEERE_CONNECTION_API);

            setIsJohnDeereConnected(data.enabled);
        };

        await handleLoadingAndErrors({
            callback,
            setIsLoading
        });
    }, [authenticatedGet, handleLoadingAndErrors]);

    const deleteJohnDeereConnection: DeleteJohnDeereConnection = useCallback(async () => {
        const callback = async () => {
            await authenticatedDelete(JOHN_DEERE_CONNECTION_API, JSON.stringify({}));
            setIsJohnDeereConnected(false);
        };

        await handleLoadingAndErrors({
            callback,
            setIsLoading
        });
    }, [authenticatedDelete, handleLoadingAndErrors]);

    return {
        createJohnDeereConnection,
        deleteJohnDeereConnection,
        fetchTokenOrRedirectAndLogIn,
        getJohnDeereConnectionStatus,
        isJohnDeereConnected,
        isLoading
    };
};

export default useJohnDeereConnection;
