import { useCallback, useEffect, useRef, useState } from 'react';

import { DatePicker, PrimaryButton, TimePicker } from '@rantizo-software/rantizo-ui';

import CheckboxWithLabel from 'components/CheckboxWithLabel';
import FormRow from 'components/FormRow';
import FormSectionContainer from 'components/FormSectionContainer';
import LargeText from 'components/LargeText';
import SideBarContentContainer from 'components/SideBarContentContainer';
import WithLabel from 'components/WithLabel';
import WithLabelAndInfo from 'components/WithLabelAndInfo';
import AreaDropdown from 'components/form/AreaDropdown';
import MultiFileUpload from 'components/form/MultiFileUpload';
import TimezoneDropdown from 'components/form/TimezoneDropdown';

import useForm from 'hooks/useForm';

import useData from './hooks/useData';
import useKmlFileUpload from './hooks/useKmlFileUpload';
import useTranslation from './hooks/useTranslation';

import { TEST_ID, TODAY } from './constants';
import type { Flight, FunctionComponent, KmlFormSchema, Props } from './types';

import styles from './styles.module.scss';

const DjiFileUploadTab: FunctionComponent<Props> = ({
    onBack,
    onFlightsCreated,
    selectedAircraft,
    testId = TEST_ID
}) => {
    const {
        addUploadedFile,
        getPresignedUrl,
        getUploadedFiles,
        modifyFileRequest,
        setUploadedFiles,
        validateFile
    } = useKmlFileUpload({ selectedAircraft });

    const {
        AREA_UNIT_TYPE,
        ASSOCIATED_APPLICATION_DATE,
        BACK,
        DRY_SPREADER_APPLICATION,
        ERROR_IMPORTING_KML_FILE,
        FILE_TYPE,
        FILE_UPLOADS,
        KML,
        SAVE_TO_FLIGHT_LOG,
        SELECT_UNIT,
        UNIT_EXPLANATION
    } = useTranslation();

    const formSchema: KmlFormSchema = {
        applicationDate: '',
        applicationStartTime: '',
        applicationTimeZone: '',
        areaUnits: 'ACRE',
        distanceUnits: 'METER'
    };

    const kmlDetailsForm = useForm(formSchema);

    const dateRef = useRef(null);
    const startTimeRef = useRef(null);
    const timezoneRef = useRef(null);
    const areaUnitRef = useRef(null);

    const { form, handleChange, handleSubmit } = kmlDetailsForm;

    const [isProcessingFlight, setIsProcessingFlight] = useState<boolean>(false);

    const [isDrySpread, setIsDrySpread] = useState<boolean>(false);

    const { getKmlFlightFromForm } = useData();

    const [isSubmitReady, setIsSubmitReady] = useState<boolean>(false);

    const updateIsSubmitReady = useCallback(() => {
        const {
            applicationDate,
            applicationStartTime,
            applicationTimeZone,
            areaUnits,
            distanceUnits
        } = form.current;

        const canSubmit = !(
            getUploadedFiles().length === 0 ||
            applicationDate === '' ||
            !applicationDate ||
            applicationStartTime === '' ||
            !applicationStartTime ||
            applicationTimeZone === '' ||
            !applicationTimeZone ||
            !areaUnits ||
            !distanceUnits
        );

        if (canSubmit !== isSubmitReady) {
            setIsSubmitReady(canSubmit);
        }
    }, [form, getUploadedFiles, isSubmitReady]);

    const handleFormChange = useCallback(
        (field: keyof KmlFormSchema) => (value: unknown) => {
            handleChange(field)(value);
            updateIsSubmitReady();
        },
        [handleChange, updateIsSubmitReady]
    );

    useEffect(() => {
        updateIsSubmitReady();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getUploadedFiles]);

    const onSubmit = useCallback(async () => {
        setIsProcessingFlight(true);

        try {
            const {
                applicationDate,
                applicationStartTime,
                applicationTimeZone,
                areaUnits,
                distanceUnits
            } = kmlDetailsForm.form.current;
            const flightType = isDrySpread ? 'DRY' : 'LIQUID';

            const kmlFlights: Flight[] = await Promise.all(
                getUploadedFiles().map(
                    async ({ objectKey }) =>
                        await getKmlFlightFromForm({
                            applicationDate,
                            applicationStartTime,
                            applicationTimeZone,
                            areaUnits,
                            distanceUnits,
                            flightType,
                            objectKey
                        })
                )
            );

            onFlightsCreated(kmlFlights);
        } catch (e) {
            console.error(e);
            alert(ERROR_IMPORTING_KML_FILE);
        } finally {
            setIsProcessingFlight(false);
        }
    }, [
        ERROR_IMPORTING_KML_FILE,
        getKmlFlightFromForm,
        getUploadedFiles,
        isDrySpread,
        kmlDetailsForm.form,
        onFlightsCreated
    ]);

    return (
        <SideBarContentContainer testId={testId}>
            <FormSectionContainer className={styles.container}>
                <WithLabel text={FILE_TYPE}>
                    <LargeText text={KML} />
                </WithLabel>

                <WithLabel text={ASSOCIATED_APPLICATION_DATE}>
                    <FormRow>
                        <DatePicker
                            isRequired
                            maxDate={TODAY}
                            onChange={handleFormChange('applicationDate')}
                            ref={dateRef}
                            testId={'applicationDate'}
                        />

                        <TimePicker
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                            onChange={(dropdownValue: any) => {
                                handleFormChange('applicationStartTime')(dropdownValue);
                            }}
                            isRequired
                            onSubmit={handleSubmit('applicationStartTime')}
                            ref={startTimeRef}
                        />

                        <TimezoneDropdown
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                            onChange={(dropdownValue: any) => {
                                handleFormChange('applicationTimeZone')(dropdownValue);
                            }}
                            isRequired
                            onSubmit={handleSubmit('applicationTimeZone')}
                            ref={timezoneRef}
                        />
                    </FormRow>
                </WithLabel>

                <CheckboxWithLabel
                    isChecked={isDrySpread}
                    onChange={(isChecked: boolean) => setIsDrySpread(isChecked)}
                    text={DRY_SPREADER_APPLICATION}
                />

                <WithLabelAndInfo text={AREA_UNIT_TYPE} tooltipText={UNIT_EXPLANATION}>
                    <AreaDropdown
                        onChange={handleFormChange('areaUnits')}
                        placeholder={SELECT_UNIT}
                        ref={areaUnitRef}
                        selected={0}
                    />
                </WithLabelAndInfo>

                <WithLabel text={FILE_UPLOADS}>
                    <MultiFileUpload
                        acceptedFileTypes={'.kml, .zip'}
                        addUploadedFile={addUploadedFile}
                        getPresignedUrl={getPresignedUrl}
                        getUploadedFiles={getUploadedFiles}
                        isEditable={true}
                        modifyFileRequest={modifyFileRequest}
                        setUploadedFiles={setUploadedFiles}
                        validateFile={validateFile}
                    />
                </WithLabel>

                <PrimaryButton isDisabled={isProcessingFlight} onClick={onBack} text={BACK} />

                <PrimaryButton
                    isDisabled={isProcessingFlight || !isSubmitReady}
                    onClick={onSubmit}
                    testId={'saveToFlightLog'}
                    text={SAVE_TO_FLIGHT_LOG}
                />
            </FormSectionContainer>
        </SideBarContentContainer>
    );
};

export default DjiFileUploadTab;
