import { useCallback, useEffect } from 'react';

import useFieldView from 'hooks/useFieldView';

import type { Props } from './types';

const useData = ({ code, source }: Props) => {
    const {
        createFieldviewConnection,
        deleteFieldViewConnection,
        fetchTokenOrRedirectAndLogIn,
        getFieldViewConnectionStatus,
        isFieldViewConnected,
        isLoading: isFieldViewLoading
    } = useFieldView();

    const handleDeleteFieldViewConnection = useCallback(async () => {
        await deleteFieldViewConnection();
        code.current = undefined;
        source.current = undefined;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [code, deleteFieldViewConnection]);

    const fetchFieldViewConnectionStatus = useCallback(async () => {
        await getFieldViewConnectionStatus();
    }, [getFieldViewConnectionStatus]);

    const handleCreateFieldViewConnectionStatus = useCallback(async () => {
        if (code.current && isFieldViewConnected === false && source.current === 'fieldView') {
            await createFieldviewConnection(code.current);
        }
    }, [code, createFieldviewConnection, isFieldViewConnected, source]);

    useEffect(() => {
        fetchFieldViewConnectionStatus();

        window.history.replaceState(null, '', window.location.pathname);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        handleCreateFieldViewConnectionStatus();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFieldViewConnected]);

    return {
        fetchFieldViewConnectionStatus,
        fetchTokenOrRedirectAndLogIn,
        handleDeleteFieldViewConnection,
        isFieldViewConnected,
        isFieldViewLoading
    };
};

export default useData;
