import { useCallback, useEffect } from 'react';

import OptionsItem from 'components/OptionsButton/OptionsItem';

import useShapeFile from 'hooks/useShapeFile';

import useData from './hooks/useData';
import useTranslation from './hooks/useTranslation';

import { TEST_ID } from './constants';
import type { FunctionComponent, Props } from './types';

const ShareToFieldView: FunctionComponent<Props> = ({
    aam,
    isDisabled,
    setIsLoading,
    testId = TEST_ID
}) => {
    const { FIELD_VIEW } = useTranslation();
    const fetchShapeFile = useData();

    const handleShapeFile = useCallback(
        async ({ shapeFile }: { shapeFile: string }) => {
            await fetchShapeFile({ shapeFile });
        },
        [fetchShapeFile]
    );

    const { initiateConnection, isLoading } = useShapeFile({ aamId: aam.id, handleShapeFile });

    useEffect(() => setIsLoading(isLoading), [isLoading, setIsLoading]);

    return (
        <OptionsItem
            isDisabled={isDisabled}
            onClick={initiateConnection}
            testId={testId}
            text={FIELD_VIEW}
        />
    );
};

export default ShareToFieldView;
