import { HorizontalContainer } from '@rantizo-software/rantizo-ui';

import ItemContainer from 'components/ItemContainer';

import { TEST_ID } from './constants';
import type { Props } from './types';

import styles from './styles.module.scss';

const ConnectionListItem = ({ children, testId = TEST_ID }: Props) => (
    <ItemContainer testId={testId}>
        <HorizontalContainer className={styles.horizontalContainer}>{children}</HorizontalContainer>
    </ItemContainer>
);

export default ConnectionListItem;
