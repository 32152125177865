import { useDateFormat } from '@rantizo-software/rantizo-ui';

import Document from 'components/pdf/Document';
import Page from 'components/pdf/Page';
import InvoiceBreakdown from 'components/pdf/invoice/InvoiceBreakdown';
import InvoiceDetails from 'components/pdf/invoice/InvoiceDetails';
import InvoicePageFooter from 'components/pdf/invoice/InvoicePageFooter';
import InvoicePageHeader from 'components/pdf/invoice/InvoicePageHeader';

import type { FunctionComponent, Props } from './types';

const WorkOrderInvoicePDF: FunctionComponent<Props> = ({ workOrder }) => {
    const { formatDate } = useDateFormat();

    const {
        appliedAcres,
        chemicalCost,
        chemicals,
        customer,
        fieldApplicationFlatFee,
        fieldApplicationHours,
        fieldApplicationRate,
        fieldApplicationRateUnit,
        grower,
        invoiceItems,
        key,
        proposedDate,
        scheduledDate,
        taxAmount,
        taxAmountUnit,
        workOrderNumber
    } = workOrder;

    const applicationDate = scheduledDate || proposedDate || new Date();
    const chemicalList = chemicals.map(({ labelName }) => labelName).join(',');

    return (
        <Document>
            <Page>
                <InvoicePageHeader />

                <InvoiceDetails
                    applicationDate={formatDate(applicationDate)}
                    customer={customer || {}}
                    grower={grower || {}}
                    thirdPartyWorkOrderNumber={Number(workOrderNumber)}
                    workOrderNumber={key || 0}
                />

                <InvoiceBreakdown
                    appliedAcres={appliedAcres}
                    chemicalCost={chemicalCost}
                    chemicalList={chemicalList}
                    fieldApplicationFlatFee={fieldApplicationFlatFee}
                    fieldApplicationHours={fieldApplicationHours}
                    fieldApplicationRate={fieldApplicationRate}
                    fieldApplicationRateUnit={fieldApplicationRateUnit}
                    invoiceItems={invoiceItems}
                    taxAmount={taxAmount}
                    taxAmountUnit={taxAmountUnit}
                />

                <InvoicePageFooter />
            </Page>
        </Document>
    );
};

export default WorkOrderInvoicePDF;
