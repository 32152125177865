const translations = {
    errors: {
        address2Max: 'Address Line 2 cannot exceed {{charMax}} characters',
        addressMax: 'Address cannot exceed {{charMax}} characters',
        addressRequired: 'Address is required',
        cityMax: 'City cannot exceed {{charMax}} characters',
        cityRequired: 'City is required',
        locationInvalid: 'The given address cannot be verified',
        nameMax: 'Company Name cannot exceed {{charMax}} characters',
        nameRequired: 'Company Name is required',
        phoneInvalid: 'Phone number is invalid',
        phoneRequired: 'Phone number is required',
        stateRequired: 'State is required',
        zipCodeRequired: 'Zip is required'
    },

    placeholders: {
        name: 'Company Name',
        optional: '(Optional)',
        phone: 'Phone Number'
    }
};

export default translations;
