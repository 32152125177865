import { useCallback, useEffect, useState } from 'react';

import useTranslation from 'components/JohnDeereExportModal/hooks/useTranslation';
import type { JohnDeereValue } from 'components/JohnDeereExportModal/types';

import useJohnDeere from 'hooks/useJohnDeere';

const useData = (enabled: boolean) => {
    const { fetchAuthorizedJohnDeereOrganizations, fetchJohnDeereOrganizations, isLoading } =
        useJohnDeere();

    const [organizations, setOrganizations] = useState<JohnDeereValue[]>([]);

    const { ORGANIZATION_ERROR } = useTranslation();

    const loadOrganizations = useCallback(async () => {
        if (enabled) {
            const authorizedOrganizations = await fetchAuthorizedJohnDeereOrganizations();

            setOrganizations(authorizedOrganizations || []);
        } else {
            const organizations = await fetchJohnDeereOrganizations();

            setOrganizations(organizations || []);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchJohnDeereOrganizations, ORGANIZATION_ERROR]);

    useEffect(() => {
        loadOrganizations();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return {
        isLoading,
        organizations
    };
};

export default useData;
