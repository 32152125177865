import useTranslation from '../useTranslation';
import { useCallback } from 'react';

import useFetch from 'hooks/useFetch';
import useToast from 'hooks/useToast';

import { ERROR, FIELD_VIEW_SHARE_URL, SUCCESS } from './constants';

const useData = () => {
    const { authenticatedPost } = useFetch();
    const { SHARE_ERROR_MESSAGE, SHARE_SUCCESS_MESSAGE } = useTranslation();
    const { broadcastToast } = useToast();

    const fetchShapeFile = useCallback(
        async ({ shapeFile }: { shapeFile: string }) => {
            try {
                await authenticatedPost(
                    FIELD_VIEW_SHARE_URL,
                    JSON.stringify({
                        downloadUrl: shapeFile
                    })
                );

                broadcastToast({
                    text: SHARE_SUCCESS_MESSAGE,
                    type: SUCCESS
                });
            } catch {
                broadcastToast({
                    text: SHARE_ERROR_MESSAGE,
                    type: ERROR
                });
            }
        },
        [SHARE_ERROR_MESSAGE, SHARE_SUCCESS_MESSAGE, authenticatedPost, broadcastToast]
    );

    return fetchShapeFile;
};

export default useData;
