import type { DroneResponse } from 'generatedTypes/equipment/models';
import { useCallback, useState } from 'react';

import { equipmentSortNickname } from '@@utils/sort';

import useAircraft from 'hooks/useAircraft';
import useGlobalStore from 'hooks/useGlobalStore';

import type { DropdownItemType } from './types';

const useData = () => {
    const { fetchAllAircrafts } = useAircraft();
    const [aircraft, setAircraft] = useState<DropdownItemType[]>([]);
    const { getValue } = useGlobalStore({ namespace: 'useAircraft' });

    const aircrafts = getValue('aircrafts');

    const fetchAircraft = useCallback(async () => {
        let aircraftResponse: DroneResponse[] | undefined;

        if (aircrafts) {
            aircraftResponse = [...aircrafts];
        } else {
            const { data, error } = await fetchAllAircrafts(true);

            aircraftResponse = data;

            if (error) {
                console.error(error);

                setAircraft([]);
            }
        }

        if (aircraftResponse) {
            aircraftResponse = [...aircraftResponse].sort(equipmentSortNickname);

            const dropdownItems = aircraftResponse.map(aircraft => ({
                label: aircraft.nickname,
                type: 'string',
                value: JSON.stringify(aircraft)
            }));

            setAircraft(dropdownItems);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return {
        aircraft,
        fetchAircraft
    };
};

export default useData;
