import { useCallback, useState } from 'react';

import { useDateFormat } from '@rantizo-software/rantizo-ui';

import useTranslation from 'components/form/ProductUsageReportDropdown/hooks/useTranslation';
import type { DropdownItemType } from 'components/form/ProductUsageReportDropdown/types';

import useProductUsageReportSummaries from 'hooks/useProductUsageReportSummaries';

const useData = () => {
    const { fetchAllProductUsageReportSummaries } = useProductUsageReportSummaries();

    const { LABEL, TBD } = useTranslation();

    const { formatDate } = useDateFormat();

    const [productUsageReportItems, setProductUsageReportItems] = useState<DropdownItemType[]>([]);

    const fetchDropdownItems = useCallback(async () => {
        const { data, error } = await fetchAllProductUsageReportSummaries();

        if (error) {
            console.error(error);

            throw new Error(JSON.stringify(error));
        }

        if (data) {
            setProductUsageReportItems(
                data.map(({ applicationSiteName, createdAt, customer, id }) => ({
                    label: LABEL(formatDate(createdAt), customer ?? '', applicationSiteName ?? TBD),
                    type: 'string',
                    value: id
                }))
            );
        }
    }, [fetchAllProductUsageReportSummaries, formatDate, LABEL, TBD]);

    return {
        fetchDropdownItems,
        productUsageReportItems
    };
};

export default useData;
