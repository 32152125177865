import { useCallback } from 'react';

import { round } from '@@utils/math';

import { JOHN_EXPORTED_MAP_REDIRECT } from 'components/JohnDeereExportModal/constants';
import useTranslation from 'components/JohnDeereExportModal/hooks/useTranslation';
import type {
    JohnDeereLegend,
    JohnDeereRange,
    MapExportParams,
    MapLegend,
    UploadToJohnDeere
} from 'components/JohnDeereExportModal/types';

import useAsAppliedMaps from 'hooks/useAsAppliedMaps';
import type { SystemOfMeasurement } from 'hooks/useAsAppliedMaps/types';
import useJohnDeere from 'hooks/useJohnDeere';
import useToast from 'hooks/useToast';

const useData = () => {
    const { exportMap } = useJohnDeere();
    const { getUnitLabel } = useAsAppliedMaps();
    const { EXPORT_ERROR, MAP_TITLE } = useTranslation();
    const { broadcastToast } = useToast();

    const coverageMapLegend = useCallback(
        (
            color: string,
            rate: number,
            systemOfMeasurement: SystemOfMeasurement
        ): JohnDeereLegend => {
            const unit = systemOfMeasurement === 'IMPERIAL' ? 'gal/ac' : 'l/m²';
            const label = `${round(rate, 2)} ${unit}`;

            return {
                ranges: [
                    {
                        hexColor: color,
                        label: label,
                        maximum: rate,
                        minimum: rate,
                        percent: 1.0
                    }
                ],
                unitId: 'gal1ac-1'
            };
        },
        []
    );

    const mapLegendToJohnDeereLegend = useCallback((mapLegend: MapLegend[]): JohnDeereLegend => {
        const ranges: JohnDeereRange[] = mapLegend.map(legend => {
            const unit = legend.systemOfMeasurement === 'IMPERIAL' ? 'gal/sec' : 'l/sec';
            const label = `${round(legend.minValue, 2)} - ${round(legend.maxValue, 2)} ${unit}`;

            return {
                hexColor: legend.color,
                label: label,
                maximum: legend.maxValue,
                minimum: legend.minValue,
                percent: legend.percentage
            };
        });

        return {
            ranges: ranges,
            unitId: 'gal1ac-1'
        };
    }, []);

    const exportMapToJohnDeere = useCallback(
        async (params: MapExportParams) => {
            const { aam, description, fieldId, mapType, metaData, organizationId, title } = params;

            if (!aam.mapImage) {
                throw new Error('Map Image Does Not Exist');
            }

            const johnDeereLegend =
                mapType === 'AAM'
                    ? mapLegendToJohnDeereLegend(aam.legend)
                    : coverageMapLegend(
                          aam.legend[0].color,
                          aam.appliedRate.value,
                          aam.fileSystemOfMeasurement
                      );

            const mapRequest = {
                description: description,
                extent: aam.extent,
                fieldId: fieldId,
                legend: johnDeereLegend,
                mapImage: aam.mapImage,
                metaData: metaData,
                organizationId: organizationId,
                title: title
            };

            await exportMap(mapRequest);
        },
        [mapLegendToJohnDeereLegend, coverageMapLegend, exportMap]
    );

    const uploadToJohnDeere = useCallback(
        async ({ aam, chemicalNames, fieldId, mapType, organizationId }: UploadToJohnDeere) => {
            try {
                const appliedRateLabel = getUnitLabel(aam.appliedRate.unitOfMeasurement);

                const metaData = [
                    {
                        name: 'Applied With',
                        value: chemicalNames
                    },
                    {
                        name: 'Application Rate',
                        value: `${round(aam.appliedRate.value, 2)} ${appliedRateLabel}`
                    }
                ];

                await exportMapToJohnDeere({
                    aam,
                    description: '',
                    fieldId,
                    mapType,
                    metaData,
                    organizationId,
                    title: MAP_TITLE(
                        new Date(aam.startTime).toLocaleDateString('en-US', {
                            day: 'numeric',
                            month: 'long',
                            weekday: 'long',
                            year: 'numeric'
                        })
                    )
                });
                window.open(JOHN_EXPORTED_MAP_REDIRECT(organizationId, fieldId), '_blank');
            } catch {
                broadcastToast({ text: EXPORT_ERROR, type: 'error' });
            }
        },
        [broadcastToast, EXPORT_ERROR, MAP_TITLE, exportMapToJohnDeere, getUnitLabel]
    );

    return {
        exportMapToJohnDeere,
        uploadToJohnDeere
    };
};

export default useData;
