const translations = {
    apiError: 'Something went wrong. Please try again.',
    createOrganization: 'Create An Organization',
    error: "Something went wrong, and we couldn't log you into this organization",
    organization: 'Organization',
    selectOrganization: 'Select an existing organization or create a new organization',
    title: 'Welcome to Rantizo!'
};

export default translations;
