import type { ColumnDef } from '@rantizo-software/rantizo-ui';

import LargeText from 'components/LargeText';
import SecondaryButton from 'components/SecondaryButton';

import useJohnDeere from 'hooks/useJohnDeere';
import type { JohnDeereValue } from 'hooks/useJohnDeere/types';

import styles from 'pages/ManageConnectionsPage/components/JohnDeereConnectionListItem/components/JohnDeereOrganizationsListTable/styles.module.scss';

export const useColumns = () => {
    const { authorizeOrganization } = useJohnDeere();
    const columns: ColumnDef<JohnDeereValue>[] = [
        {
            createCell: ({ rowData }) => rowData.original.name,
            createHeader: () => 'Name',
            id: 'name'
        },
        {
            createCell: ({ rowData }) => {
                const isDisabled = rowData.original.links.find(link => link.rel === 'connections');

                if (isDisabled) {
                    return (
                        <SecondaryButton
                            onClick={() =>
                                authorizeOrganization(
                                    rowData.original,
                                    `${import.meta.env.VITE_RANTIZO_API_BASE_URL}/connections`
                                )
                            }
                            className={styles.secondaryButton}
                            text="Enable"
                        />
                    );
                }

                return <LargeText className={styles.secondaryButton} text="Enabled" />;
            },
            createHeader: () => 'Enabled',
            id: 'enabled'
        }
    ];

    return columns;
};

export default useColumns;
