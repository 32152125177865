import { useCallback, useState } from 'react';

const useFormError = (onError?: (error: string) => void) => {
    const [errorMessage, setErrorMessage] = useState<string | undefined>();

    const handleError = useCallback(
        (_dropdownValue: unknown, error: string) => {
            onError?.(error);
            setErrorMessage(error);
        },
        [onError]
    );

    const clearError = () => setErrorMessage('');

    return { clearError, errorMessage, handleError };
};

export default useFormError;
