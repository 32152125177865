import { forwardRef, useCallback } from 'react';

import { Dropdown, WithErrorMessage } from '@rantizo-software/rantizo-ui';

import useFormError from 'hooks/useFormError';

import { DROPDOWN_ITEMS, TEST_ID } from './constants';
import type { OnError, Props } from './types';

const AreaDropdown = forwardRef<HTMLSelectElement, Props>(
    (
        {
            className = '',
            hasError = false,
            isEditable = true,
            isRequired = false,
            label = '',
            onChange,
            onError,
            onSubmit,
            onValid,
            placeholder,
            selected,
            testId = TEST_ID
        },
        ref
    ) => {
        const { clearError, errorMessage, handleError } = useFormError(onError);

        const handleChange = useCallback(
            (_selectedValue: unknown, _handleError: OnError, index: number) => {
                clearError();
                onChange?.(DROPDOWN_ITEMS[index].value);
            },
            [onChange, clearError]
        );

        const hasAnError = Boolean(hasError || errorMessage);

        return (
            <WithErrorMessage className={className} text={errorMessage}>
                <Dropdown
                    className={className}
                    hasError={hasAnError}
                    isEditable={isEditable}
                    isRequired={isRequired}
                    items={DROPDOWN_ITEMS}
                    label={label}
                    onChange={handleChange}
                    onError={handleError}
                    onSubmit={onSubmit}
                    onValid={onValid}
                    placeholder={placeholder}
                    ref={ref}
                    selected={selected}
                    testId={testId}
                />
            </WithErrorMessage>
        );
    }
);

AreaDropdown.displayName = 'AreaDropdown';

export default AreaDropdown;
