export const INITIAL_STATE = {
    faa_reports: false,
    field_view: false,
    flight_log_list: false,
    intercom_chat: false,
    john_deere: false,
    manage_connections: false,
    operator_map_tile: false,
    pdf_extraction: false,
    product_usage_report_delete: false,
    product_usage_report_edit: false
};
