import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { HorizontalContainer, useDate } from '@rantizo-software/rantizo-ui';

import FaaListItemMenu from 'components/FaaListItemMenu';
import ItemContainer from 'components/ItemContainer';
import ListText from 'components/ListText';
import Time from 'components/Time';

import usePageRoutes from 'hooks/usePageRoutes';

import { TEST_ID } from './constants';
import type { FunctionComponent, Props } from './types';

import styles from './styles.module.scss';

const FaaReportListItem: FunctionComponent<Props> = ({
    handleDelete,
    report,
    testId = TEST_ID
}) => {
    const { totalDrones, totalFlightTimeMilliseconds, totalFlights, totalPilots } = report;

    const navigate = useNavigate();
    const { viewFaaReportPage } = usePageRoutes();

    const { convertMillisecondsToDecimalTimeString } = useDate();

    const onView = useCallback(() => {
        navigate(viewFaaReportPage(report.id));
    }, [navigate, report.id, viewFaaReportPage]);

    const handleReportDelete = useCallback(() => {
        handleDelete?.(report.id);
    }, [handleDelete, report.id]);

    return (
        <ItemContainer className={styles.faaReportListItem} testId={testId}>
            <HorizontalContainer className={styles.horizontalContainer} onClick={onView}>
                <Time text={convertMillisecondsToDecimalTimeString(totalFlightTimeMilliseconds)} />

                <ListText text={totalFlights} />

                <ListText text={totalDrones} />

                <ListText text={totalPilots} />
            </HorizontalContainer>

            <FaaListItemMenu
                faaReportId={report.id}
                handleDelete={handleReportDelete}
                isListView={true}
            />
        </ItemContainer>
    );
};

export default FaaReportListItem;
