import { useCallback, useEffect, useState } from 'react';

import type { JohnDeereValue } from 'components/JohnDeereExportModal/types';

import useJohnDeere from 'hooks/useJohnDeere';

const useData = (organizationId?: string) => {
    const { authorizeOrganization, fetchJohnDeereFields, isLoading } = useJohnDeere();

    const [fields, setFields] = useState<JohnDeereValue[]>([]);

    const loadFieldsForOrganization = useCallback(
        async () => {
            if (!organizationId) {
                return;
            }
            setFields([]);

            const fieldResponse = await fetchJohnDeereFields(organizationId);

            setFields(fieldResponse || []);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [fields, authorizeOrganization, fetchJohnDeereFields, organizationId]
    );

    useEffect(() => {
        loadFieldsForOrganization();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [organizationId]);

    return {
        fields,
        isLoading
    };
};

export default useData;
