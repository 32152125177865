import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('johnDeereConnectionListItem');

    return {
        AUTHORIZED_ORGANIZATIONS_EXPLAINER_TEXT: t('authorizedOrganizationsExplainerText'),
        AUTHORIZED_ORGANIZATIONS_LABEL: t('authorizedOrganizationsLabel'),
        CONNECT: t('connect'),
        DISCONNECT: t('disconnect'),
        JOHN_DEERE_DESCRIPTION: t('johnDeereDescription'),
        JOHN_DEERE_TITLE: t('johnDeereTitle')
    };
};

export default useTranslation;
