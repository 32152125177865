import { useCallback, useMemo, useRef, useState } from 'react';

import { useDate, useDateFormat } from '@rantizo-software/rantizo-ui';

import { COMPLETE_DATE_LENGTH, DATE_FORMAT } from './constants';
import type {
    DateInterval,
    DateRange,
    Props,
    RangeMapKeys,
    SelectRangeEventHandler,
    SetDateRangeArgs
} from './types';

const initRange: DateRange = { from: undefined, to: undefined };

const useDateRangePicker = ({ onChange, rangeInterval, refs, value }: Props) => {
    const { endInputRef, startInputRef } = refs;
    const containerRef = useRef<HTMLDivElement>(null);
    const dropdownRef = useRef<HTMLInputElement>(null);
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [selectedDateRange, setSelectedDateRange] = useState<DateRange>(value || initRange);

    const [startInputValue, setStartInputValue] = useState<string | undefined>(undefined);
    const [endInputValue, setEndInputValue] = useState<string | undefined>(undefined);

    const { isDateValid, isDateWithinInterval, parseDate, parseDateRange, validateDateRange } =
        useDate();
    const { formatDate, formatDateRange } = useDateFormat();

    const inputsRefMap = useMemo(
        () => ({
            end: endInputRef,
            start: startInputRef
        }),
        [endInputRef, startInputRef]
    );

    const handleClose = useCallback(() => {
        setIsOpen(false);
    }, [setIsOpen]);

    const reset = useCallback(() => {
        onChange?.(initRange);
        setSelectedDateRange(initRange);
        setStartInputValue(undefined);
        setEndInputValue(undefined);
    }, [onChange]);

    const handleBlur = useCallback(
        (event: React.FocusEvent<HTMLInputElement>) => {
            const { relatedTarget } = event;

            if (selectedDateRange.from && isDateValid({ date: selectedDateRange.from })) {
                setStartInputValue(formatDate(selectedDateRange.from));
            }

            if (selectedDateRange.to && isDateValid({ date: selectedDateRange.to })) {
                setEndInputValue(formatDate(selectedDateRange.to));
            }

            if (relatedTarget === null || !dropdownRef.current?.contains(relatedTarget)) {
                if (
                    relatedTarget === startInputRef.current ||
                    relatedTarget === endInputRef.current
                ) {
                    return;
                }
                handleClose();
            } else {
                startInputRef.current?.focus();
            }
        },

        [endInputRef, handleClose, isDateValid, formatDate, selectedDateRange, startInputRef]
    );

    const handleChange: SelectRangeEventHandler = useCallback(
        date => {
            if (!date) {
                reset();
                onChange?.(initRange);
            } else {
                date.from?.setHours(0);
                date.to?.setHours(23);
                const { from, to } = formatDateRange(date);

                setSelectedDateRange(date);

                if (from) {
                    setStartInputValue(formatDate(from));
                    startInputRef.current.value = from;
                }

                if (to) {
                    setEndInputValue(formatDate(to));
                    endInputRef.current.value = to;
                }

                if (from && to) {
                    handleClose();
                }

                onChange?.(date);
            }
        },
        [endInputRef, formatDateRange, formatDate, handleClose, onChange, reset, startInputRef]
    );

    const setDateRange = useCallback(
        ({ dateString, from = false, interval }: SetDateRangeArgs) => {
            const parsedDate = parseDate({ dateString, format: DATE_FORMAT });

            if (isDateWithinInterval({ date: parsedDate, interval })) {
                setSelectedDateRange(prev =>
                    from ? { ...prev, from: parsedDate } : { ...prev, to: parsedDate }
                );

                return;
            }
        },
        [isDateWithinInterval, parseDate]
    );

    const handleTextChange = useCallback(() => {
        const startValue: string = startInputRef.current?.value || null;
        const endValue: string = endInputRef.current?.value || null;

        setStartInputValue(startValue);
        setEndInputValue(endValue);

        const isCompleteStartDate =
            startValue && startValue.length && startValue.length === COMPLETE_DATE_LENGTH
                ? true
                : false;
        const isCompleteEndDate =
            endValue && endValue.length && endValue.length === COMPLETE_DATE_LENGTH ? true : false;

        if (isCompleteStartDate && isCompleteEndDate) {
            const { from, to } = parseDateRange({
                format: DATE_FORMAT,
                from: startValue,
                to: endValue
            });

            const validatedDateRange = validateDateRange({ dates: { from, to } });

            if (validatedDateRange) {
                setSelectedDateRange(validatedDateRange);
                onChange?.(validatedDateRange);
                handleClose();
            }

            return;
        }

        if (startValue && !endValue) {
            setDateRange({ dateString: startValue, from: true, interval: rangeInterval });
        }

        if (endValue && !startValue) {
            setDateRange({ dateString: endValue, interval: rangeInterval });
        }
        // eslint-disable-next-line
    }, [
        endInputRef,
        parseDate,
        rangeInterval,
        selectedDateRange,
        setDateRange,
        startInputRef,
        validateDateRange
    ]);

    const handleOpen = useCallback(
        (inputName: RangeMapKeys) => {
            inputsRefMap[inputName].current?.focus();
            setIsOpen(true);
        },
        [setIsOpen, inputsRefMap]
    );

    const { addMonthsToDate, isDateAfter, isDateBefore } = useDate();

    const disableDaysFromRange = useCallback(
        (day: Date) => {
            if (!selectedDateRange?.from && !selectedDateRange?.to) {
                return false;
            }

            const { from, to } = selectedDateRange;

            const fromDateMax = from && addMonthsToDate({ date: from, months: 6 });
            const toDateMax = to && addMonthsToDate({ date: to, months: -6 });

            const isAfterDate = isDateAfter({
                date: day,
                dateToCompare: fromDateMax
            });

            if (fromDateMax && isAfterDate) {
                return true;
            }

            const isBeforeDate = isDateBefore({
                date: day,
                dateToCompare: toDateMax
            });

            if (toDateMax && isBeforeDate) {
                return true;
            }

            return false;
        },
        // eslint-disable-next-line
        [selectedDateRange]
    );

    const dayPickerInterval: DateInterval = useMemo(
        () => ({
            after: rangeInterval.end as Date,
            before: rangeInterval.start as Date
        }),
        [rangeInterval]
    );

    return {
        containerRef,
        dayPickerInterval,
        disableDaysFromRange,
        dropdownRef,
        endInputValue,
        handleBlur,
        handleChange,
        handleClose,
        handleOpen,
        handleTextChange,
        isOpen,
        reset,
        selectedDateRange,
        setIsOpen,
        startInputValue
    };
};

export default useDateRangePicker;
