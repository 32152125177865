import { useCallback, useState } from 'react';

import { ContentText, ModalTitle, PrimaryButton } from '@rantizo-software/rantizo-ui';

import FormSectionContainer from 'components/FormSectionContainer';
import Link from 'components/Link';
import ModalOverlay from 'components/ModalOverlay';
import JohnDeereOrganizationFieldsDropdownField from 'components/form/FormFields/JohnDeereOrganizationFieldsDropdownField';
import JohnDeereOrganizationsDropdownField from 'components/form/FormFields/JohnDeereOrganizationsDropdownField';

import useFormState from 'hooks/useFormState';

import useData from './hooks/useData';
import useTranslation from './hooks/useTranslation';

import {
    FIELD_FORM_KEY,
    INITIAL_SCHEMA,
    MANAGE_CONNECTIONS_PATH,
    ORGANIZATION_FORM_KEY,
    TEST_ID
} from './constants';
import type { FunctionComponent, JohnDeereExportFormSchema, Props } from './types';

import styles from './styles.module.scss';

const JohnDeereExportModal: FunctionComponent<Props> = ({
    aam,
    chemicalNames,
    mapType,
    onClose,
    testId = TEST_ID
}) => {
    const [selectedOrganizationId, setSelectedOrganizationId] = useState<string | undefined>(
        undefined
    );

    const { uploadToJohnDeere } = useData();

    const handleSubmit = useCallback(async () => {
        const { fieldId, organizationId } =
            johnDeereExportForm.getValues() as JohnDeereExportFormSchema;

        await uploadToJohnDeere({
            aam,
            chemicalNames,
            fieldId,
            mapType,
            organizationId
        });
        onClose();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [aam, chemicalNames, mapType, onClose, uploadToJohnDeere]);

    const johnDeereExportForm = useFormState<JohnDeereExportFormSchema>({
        initialSchema: INITIAL_SCHEMA,
        onFormChange(data) {
            setSelectedOrganizationId(data.formData.organizationId);
        },
        onSubmit: handleSubmit
    });

    const { DESCRIPTION, DISCONNECT_FROM_JOHN_DEERE, TITLE, UPLOAD } = useTranslation();

    return (
        <ModalOverlay onClose={onClose} testId={testId}>
            <FormSectionContainer>
                <ModalTitle text={TITLE} />

                <ContentText className={styles.centerText} text={DESCRIPTION} />

                <JohnDeereOrganizationsDropdownField<JohnDeereExportFormSchema>
                    authorizedOrganizationsOnly={true}
                    form={johnDeereExportForm}
                    name={ORGANIZATION_FORM_KEY}
                />

                <JohnDeereOrganizationFieldsDropdownField<JohnDeereExportFormSchema>
                    form={johnDeereExportForm}
                    name={FIELD_FORM_KEY}
                    organizationId={selectedOrganizationId}
                />

                <PrimaryButton onClick={handleSubmit} text={UPLOAD} />

                <Link text={DISCONNECT_FROM_JOHN_DEERE} to={MANAGE_CONNECTIONS_PATH} />
            </FormSectionContainer>
        </ModalOverlay>
    );
};

export default JohnDeereExportModal;
