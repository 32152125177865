import useJohnDeereConnection from './hooks/useJohnDeereConnection';
import useJohnDeereFields from './hooks/useJohnDeereFields';
import useJohnDeereMapLayers from './hooks/useJohnDeereMapLayers';
import useJohnDeereOrganizations from './hooks/useJohnDeereOrganizations';

const useJohnDeere = () => {
    const {
        authorizeOrganization,
        authorizedOrganizations,
        fetchAuthorizedJohnDeereOrganizations,
        fetchJohnDeereOrganizations,
        isLoading: isJohnDeereOrganizationsLoading,
        organizations
    } = useJohnDeereOrganizations();
    const { exportMap, isLoading: isJohnDeereMapLayersLoading } = useJohnDeereMapLayers();
    const { fetchJohnDeereFields, isLoading: isJohnDeereFieldsLoading } = useJohnDeereFields();

    const {
        createJohnDeereConnection,
        deleteJohnDeereConnection,
        fetchTokenOrRedirectAndLogIn,
        getJohnDeereConnectionStatus,
        isJohnDeereConnected,
        isLoading: isJohnDeereConnectionLoading
    } = useJohnDeereConnection();

    return {
        authorizeOrganization,
        authorizedOrganizations,
        createJohnDeereConnection,
        deleteJohnDeereConnection,
        exportMap,
        fetchAuthorizedJohnDeereOrganizations,
        fetchJohnDeereFields,
        fetchJohnDeereOrganizations,
        fetchTokenOrRedirectAndLogIn,
        getJohnDeereConnectionStatus,
        isJohnDeereConnected,
        isLoading:
            isJohnDeereConnectionLoading ||
            isJohnDeereOrganizationsLoading ||
            isJohnDeereMapLayersLoading ||
            isJohnDeereFieldsLoading,
        organizations
    };
};

export default useJohnDeere;
