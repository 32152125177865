import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('siteHeader');

    return {
        AAM: t('aam'),
        ACCOUNT: t('account'),
        COPYRIGHT: (year: string) => t('copyright', { year }),
        CREATE_PRODUCT_USAGE_REPORT: t('createProductUsageReport'),
        CREATE_WORK_ORDER: t('createWorkOrder'),
        DASHBOARD: t('dashboard'),
        FAA_REPORTS: t('faaReports'),
        FLIGHT_LOG: t('flightLog'),
        LOGOUT: t('logout'),
        MANAGE_CONNECTIONS: t('manageConnections'),
        MANAGE_ORGANIZATION: t('manageOrganization'),
        MY_ORGANIZATION: t('myOrganization'),
        PRODUCT_USAGE_REPORTS: t('productUsageReports'),
        SERVICE_OPERATOR_MAP: t('serviceOperatorMap'),
        TERMS_OF_SERVICE: t('termsOfService'),
        WORK_ORDERS: t('workOrders')
    };
};

export default useTranslation;
