import { atom } from 'recoil';

import type { DroneResponse } from './types';

const aircraftsAtom = atom<DroneResponse[]>({
    default: undefined,
    key: 'aircrafts'
});

const state = {
    atoms: {
        aircraftsAtom
    }
};

export default state;
