const translations = {
    authorizedOrganizationsExplainerText: `To sync application maps, you must enable each organization in your John
                            Deere Operations Center™ account individually. This is required to
                            grant the necessary permissions for data transfer and ensure secure and
                            accurate mapping for each organization.`,
    authorizedOrganizationsLabel: 'Authorized Organizations',
    connect: 'Connect',
    disconnect: 'Disconnect',
    johnDeereDescription: 'Lorem Ipsum',
    johnDeereTitle: 'John Deere Operations Center'
};

export default translations;
