import { useCallback } from 'react';

import { VerticalContainer } from '@rantizo-software/rantizo-ui';

import OrganizationListItem from 'components/OrganizationListItem';

import useAccessToken from 'hooks/useAccessToken';
import useCurrentOrganization from 'hooks/useCurrentOrganization';

import useTranslation from './hooks/useTranslation';

import { TEST_ID } from './constants';
import type { FunctionComponent, Organization, Props } from './types';

const OrganizationList: FunctionComponent<Props> = ({ organizations, testId = TEST_ID }) => {
    const { fetchToken } = useAccessToken();
    const { ERROR } = useTranslation();
    const { setCurrentOrganization } = useCurrentOrganization();

    const loginToOrg = useCallback(
        async (org: Organization) => {
            const token = await fetchToken(org.auth0Id, false);

            if (token) {
                await setCurrentOrganization(org);

                return;
            }

            alert(ERROR);
        },
        [ERROR, fetchToken, setCurrentOrganization]
    );

    const handleClick = useCallback(async (org: Organization) => loginToOrg(org), [loginToOrg]);

    return organizations?.length > 0 ? (
        <VerticalContainer testId={testId}>
            {organizations.map((org: Organization) => (
                <OrganizationListItem
                    key={org.id}
                    onClick={() => handleClick(org)}
                    text={org.name}
                />
            ))}
        </VerticalContainer>
    ) : null;
};

export default OrganizationList;
