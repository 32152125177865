/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * AcreConnect API
 * AcreConnect API documentation
 * OpenAPI spec version: 1.0
 */

export type FlightQueryFlightModification = typeof FlightQueryFlightModification[keyof typeof FlightQueryFlightModification];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FlightQueryFlightModification = {
  REMOVE_FERRYING_LINES: 'REMOVE_FERRYING_LINES',
} as const;
