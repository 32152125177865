import { Checkbox } from '@rantizo-software/rantizo-ui';

import { TEST_ID } from './constants';
import type { CheckboxChangeEvent, FunctionComponent, Props } from './types';

const WithCheckbox: FunctionComponent<Props> = props => {
    const { children, className = '', isChecked, isDisabled, onChange, testId = TEST_ID } = props;

    return (
        <>
            <Checkbox
                className={className}
                isChecked={isChecked}
                isDisabled={isDisabled}
                onChange={(event: CheckboxChangeEvent) => onChange?.(event.target.checked)}
                testId={testId}
            />

            {children}
        </>
    );
};

export default WithCheckbox;
