export const TEST_ID = 'fieldViewConnectionListItem';

export const FIELD_VIEW = 'fieldView';

export const MANAGE_CONNECTIONS_PAGE = '/connections';

export const CODE = 'code';

export const SOURCE = 'source';

export const UNDEFINED = 'undefined';
