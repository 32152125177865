import { useCallback, useRef, useState } from 'react';

import useFetch from 'hooks/useFetch';
import type { JohnDeereValue } from 'hooks/useJohnDeere/types';

import { ORGANIZATIONS_API } from './constants';
import type {
    AuthorizeContributions,
    AuthorizeOrganization,
    FetchJohnDeereOrganizations
} from './types';

const useJohnDeereOrganizations = () => {
    const [isLoading, setIsLoading] = useState(false);
    const { authenticatedGet, handleLoadingAndErrors } = useFetch();
    const organizations = useRef<JohnDeereValue[]>([]);
    const authorizedOrganizations = useRef<JohnDeereValue[]>([]);

    const fetchJohnDeereOrganizations: FetchJohnDeereOrganizations = useCallback(async () => {
        const callback = async () => {
            const { data } = await authenticatedGet(`${ORGANIZATIONS_API}`);

            setIsLoading(false);

            return data;
        };

        const data = await handleLoadingAndErrors({
            callback,
            setIsLoading
        });

        organizations.current = data;

        return data;
    }, [authenticatedGet, handleLoadingAndErrors]);

    const fetchAuthorizedJohnDeereOrganizations: FetchJohnDeereOrganizations =
        useCallback(async () => {
            const callback = async () => {
                const { data } = await authenticatedGet(`${ORGANIZATIONS_API}?enabled=true`);

                setIsLoading(false);

                return data;
            };

            const data = await handleLoadingAndErrors({
                callback,
                setIsLoading
            });

            authorizedOrganizations.current = data;

            return data;
        }, [authenticatedGet, handleLoadingAndErrors]);

    const authorizeContributions: AuthorizeContributions = useCallback(
        async organizationId => {
            const callback = async () => {
                await authenticatedGet(
                    `${ORGANIZATIONS_API}/${organizationId}/contributions/authorize`
                );
            };

            await handleLoadingAndErrors({
                callback,
                setIsLoading
            });
        },
        [authenticatedGet, handleLoadingAndErrors]
    );

    const authorizeOrganization: AuthorizeOrganization = useCallback(
        async (organization, redirectUri) => {
            const connectionLinks = organization.links.filter(link => link.rel === 'connections');

            if (connectionLinks.length === 0) {
                authorizeContributions(organization.id);

                return;
            }

            const connectionLink = connectionLinks[0];

            document.location = `${connectionLink.uri}?redirect_uri=${redirectUri}`;
        },
        [authorizeContributions]
    );

    return {
        authorizeOrganization,
        authorizedOrganizations,
        fetchAuthorizedJohnDeereOrganizations,
        fetchJohnDeereOrganizations,
        isLoading,
        organizations
    };
};

export default useJohnDeereOrganizations;
