import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import OptionsItem from 'components/OptionsButton/OptionsItem';
import OptionsButtonTextWithArrow from 'components/OptionsButtonTextWithArrow';
import ShareToFieldView from 'components/ShareToFieldView';
import ShareToJohnDeere from 'components/ShareToJohnDeere';

import useFeatureFlags from 'hooks/useFeatureFlags';
import useFieldView from 'hooks/useFieldView';
import useJohnDeere from 'hooks/useJohnDeere';
import usePageRoutes from 'hooks/usePageRoutes';

import useTranslation from './hooks/useTranslation';

import { TEST_ID } from './constants';
import type { FunctionComponent, Props } from './types';

import styles from './styles.module.scss';

const MapShareButton: FunctionComponent<Props> = ({
    aam,
    chemicalNames,
    jobId,
    mapType,
    productUsageReportId,
    testId = TEST_ID
}) => {
    const navigate = useNavigate();

    const { manageConnectionsPage } = usePageRoutes();
    const [isLoading, setIsLoading] = useState(false);

    const { FIELD_VIEW, JOHN_DEERE } = useFeatureFlags();
    const { MANAGE_CONNECTIONS, SHARE_TO } = useTranslation();

    const { getFieldViewConnectionStatus, isFieldViewConnected } = useFieldView();
    const { getJohnDeereConnectionStatus, isJohnDeereConnected } = useJohnDeere();

    useEffect(() => {
        getFieldViewConnectionStatus();
        getJohnDeereConnectionStatus();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const navigateToManageConnections = useCallback(() => {
        navigate(manageConnectionsPage());
    }, [manageConnectionsPage, navigate]);

    return (
        <OptionsButtonTextWithArrow isLoading={isLoading} testId={testId} text={SHARE_TO}>
            {JOHN_DEERE && (
                <ShareToJohnDeere
                    aam={aam}
                    chemicalNames={chemicalNames}
                    isDisabled={!isJohnDeereConnected}
                    jobId={jobId}
                    mapType={mapType}
                    productUsageReportId={productUsageReportId}
                />
            )}

            {MANAGE_CONNECTIONS && FIELD_VIEW && (
                <ShareToFieldView
                    aam={aam}
                    isDisabled={!isFieldViewConnected}
                    setIsLoading={setIsLoading}
                />
            )}

            {MANAGE_CONNECTIONS && (!isFieldViewConnected || !isJohnDeereConnected) && (
                <OptionsItem
                    className={styles.optionsItem}
                    onClick={navigateToManageConnections}
                    text={MANAGE_CONNECTIONS}
                />
            )}
        </OptionsButtonTextWithArrow>
    );
};

export default MapShareButton;
