import { isEqual } from 'lodash';
import { useCallback } from 'react';

import { formatPhoneNumberForApi } from '@@utils/data';

import useAccessToken from 'hooks/useAccessToken';
import useFetch from 'hooks/useFetch';
import useGlobalStore from 'hooks/useGlobalStore';

import { MY_ORGANIZATION_API, PERSON_OF_CONTACT_API } from './constants';
import type { MyOrganization, OrganizationRequest, PersonOfContact } from './types';

const useCurrentOrganization = () => {
    const { getValue, setValue } = useGlobalStore({ namespace: 'useCurrentOrganization' });

    const { fetchToken } = useAccessToken();
    const { authenticatedPut, httpGet } = useFetch();

    const currentOrganization = getValue('currentOrganization') as MyOrganization;
    const isNewOrganization = getValue('isNewOrganization');
    const personOfContact = getValue('orgPersonOfContact') as PersonOfContact;

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const saveCurrentOrganizationToStore = useCallback(setValue('currentOrganization'), []);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const saveIsNewOrganizationToStore = useCallback(setValue('isNewOrganization'), []);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const savePersonOfContactToStore = useCallback(setValue('orgPersonOfContact'), []);

    const setCurrentOrganization = useCallback(
        async (org: MyOrganization) => {
            saveCurrentOrganizationToStore(org);
        },
        [saveCurrentOrganizationToStore]
    );

    const setIsNewOrganization = useCallback(
        async (isNewOrganization: boolean) => {
            saveIsNewOrganizationToStore(isNewOrganization);
        },
        [saveIsNewOrganizationToStore]
    );

    const fetchCurrentOrganization = useCallback(async () => {
        const token = await fetchToken();

        if (!token) {
            throw new Error('User not authenticated');
        }

        const response = await httpGet(MY_ORGANIZATION_API, null, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        if (response.error) {
            setCurrentOrganization(undefined);

            return;
        }

        setCurrentOrganization(response.data as MyOrganization);
    }, [fetchToken, httpGet, setCurrentOrganization]);

    const fetchPersonOfContact = useCallback(
        async (cached: boolean = true) => {
            const sameOrg =
                personOfContact !== undefined &&
                currentOrganization !== undefined &&
                currentOrganization.id === personOfContact?.organizationId;

            if ((personOfContact === null || sameOrg) && cached) {
                return personOfContact;
            }

            const token = await fetchToken();

            if (!token) {
                throw new Error('User not authenticated');
            }

            const response = await httpGet(PERSON_OF_CONTACT_API, null, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            if (response.error || isEqual(response.data, {})) {
                savePersonOfContactToStore(null);

                return null;
            }

            const contact = response.data as PersonOfContact;

            savePersonOfContactToStore(contact);

            return contact;
        },
        [currentOrganization, fetchToken, httpGet, personOfContact, savePersonOfContactToStore]
    );

    const updateMyOrganization = useCallback(
        async (organization: MyOrganization) => {
            const { data, error } = await authenticatedPut(
                MY_ORGANIZATION_API,
                JSON.stringify(organization)
            );

            if (error) {
                throw new Error('Error updating organization');
            }

            // update current organization
            setCurrentOrganization(data);

            return data;
        },
        [authenticatedPut, setCurrentOrganization]
    );

    /*
     * @deprecated Use the updateMyOrganization instead
     */
    const updateCurrentOrganization = useCallback(
        async (values: OrganizationRequest) => {
            const {
                coa,
                companyName: name,
                faaDocketNumber,
                faaExemptionNumber,
                location,
                phone: phoneNumber
            } = values;
            const { address1, address2, city, state, zipCode } = location;

            const requestData = {
                address1,
                address2,
                city,
                coa,
                country: 'US',
                faaDocketNumber,
                faaExemptionNumber,
                location: { coordinates: [-83.1552834, 42.5576608], type: 'Point' },
                name,
                // remove once location is not required in API request
                phoneCountryCode: '1',

                phoneNumber: formatPhoneNumberForApi(phoneNumber),
                state,
                zipCode
            };

            const result =
                (await authenticatedPut(MY_ORGANIZATION_API, JSON.stringify(requestData))) || {};

            return result;
        },
        [authenticatedPut]
    );

    return {
        currentOrganization,
        fetchCurrentOrganization,
        fetchPersonOfContact,
        isNewOrganization,
        personOfContact,
        setCurrentOrganization,
        setIsNewOrganization,
        updateCurrentOrganization,
        updateMyOrganization
    };
};

export default useCurrentOrganization;
