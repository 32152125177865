import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('fieldViewConnectionListItem');

    return {
        CONNECT: t('connect'),
        DISCONNECT: t('disconnect'),
        FIELD_VIEW_DESCRIPTION: t('fieldViewDescription'),
        FIELD_VIEW_TITLE: t('fieldViewTitle')
    };
};

export default useTranslation;
