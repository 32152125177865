import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { useAuth0 } from '@auth0/auth0-react';

const SignupPage = () => {
    const { loginWithRedirect } = useAuth0();
    const { tag } = useParams();

    useEffect(() => {
        loginWithRedirect({
            authorizationParams: {
                registration_tag: tag,
                screen_hint: 'signup'
            }
        });
    }, [loginWithRedirect, tag]);

    return null;
};

export default SignupPage;
