import { useCallback, useEffect, useRef, useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import type { AuthorizationParams } from '@auth0/auth0-react';

import AccountDetails from 'components/AccountDetails';
import AircraftList from 'components/AircraftList';
import AuthChecker from 'components/AuthChecker';
import AuthenticationProvider from 'components/AuthenticationProvider';
import ChatSupport from 'components/ChatSupport';
import ControllerList from 'components/ControllerList';
import EquipmentDetails from 'components/EquipmentDetails';
import KmlImportForm from 'components/KmlImport';
import OrganizationDetails from 'components/OrganizationDetails';
import OrganizationSubscriptionDetails from 'components/OrganizationSubscriptionDetails';
import TeamDetails from 'components/TeamDetails';

import useCurrentOrganization from 'hooks/useCurrentOrganization';
import useFeatureFlags from 'hooks/useFeatureFlags';

import Layout from 'layouts/Layout';
import SimpleLayout from 'layouts/SimpleLayout';
import WideLayout from 'layouts/WideLayout';

import AccountPage from 'pages/AccountPage';
import AsAppliedMapResultsPage from 'pages/AsAppliedMapResultsPage';
import CertificatesAndExemptionsPage from 'pages/CertificatesAndExemptionsPage';
import CreateFaaReportPage from 'pages/CreateFaaReportPage';
import EditAircraftPage from 'pages/EditAircraftPage';
import EditCompanyDetailsPage from 'pages/EditCompanyDetailsPage';
import EditControllerPage from 'pages/EditControllerPage';
import EditPersonOfContactPage from 'pages/EditPersonOfContactPage';
import EditUserDetailsPage from 'pages/EditUserDetailsPage';
import FaaReportsPage from 'pages/FaaReportsPage';
import FieldViewRedirectPage from 'pages/FieldViewRedirectPage';
import FlightLogPage from 'pages/FlightLogPage';
import FlightPage from 'pages/FlightPage';
import GenerateAsAppliedMapPage from 'pages/GenerateAsAppliedMapPage';
import HomePage from 'pages/HomePage';
import JohnDeereRedirectPage from 'pages/JohnDeereRedirectPage';
import LoginPage from 'pages/LoginPage';
import LogoutPage from 'pages/LogoutPage';
import ManageConnectionsPage from 'pages/ManageConnectionsPage';
import ManageOrganizationPage from 'pages/ManageOrganizationPage';
import MapboxTestPage from 'pages/MapboxTestPage';
import MapsListPage from 'pages/MapsListPage';
import NewAircraftPage from 'pages/NewAircraftPage';
import NewControllerPage from 'pages/NewControllerPage';
import NewProductUsageReportPage from 'pages/NewProductUsageReportPage';
import OperatorMapPage from 'pages/OperatorMapPage';
import ProductUsageReportListPage from 'pages/ProductUsageReportListPage';
import ProductUsageReportPage from 'pages/ProductUsageReportPage';
import SignupPage from 'pages/SignupPage';
import TermsAndConditionsPage from 'pages/TermsAndConditionsPage';
import UploadFlightLogPage from 'pages/UploadFlightLogPage';
import VerifyAccountPage from 'pages/VerifyAccountPage';
import VerifyEmailPage from 'pages/VerifyEmailPage';
import VerifyOrganizationPage from 'pages/VerifyOrganizationPage';
import WorkOrderPage from 'pages/WorkOrderPage';
import WorkOrderReportPage from 'pages/WorkOrderReportPage';
import BadPathErrorPage from 'pages/errors/BadPathErrorPage';

const AppRoutes = () => {
    const [currentLayout, setCurrentLayout] = useState<string>('narrow');

    const handleLayoutSwitch = useCallback((layoutType: string) => {
        setCurrentLayout(layoutType);
    }, []);

    const { currentOrganization } = useCurrentOrganization();
    const { FAA_REPORTS, MANAGE_CONNECTIONS, OPERATOR_MAP_TILE } = useFeatureFlags();

    const authParams = useRef<AuthorizationParams>({
        audience: import.meta.env.VITE_AUTH0_AUDIENCE,
        redirect_uri: window.location.origin
    });

    useEffect(() => {
        if (currentOrganization) {
            authParams.current = {
                ...authParams.current,
                organization: currentOrganization.auth0Id
            };
        }
    }, [currentOrganization]);

    return (
        <Routes>
            <Route
                element={
                    <AuthenticationProvider
                        authorizationParams={authParams.current}
                        clientId={import.meta.env.VITE_AUTH0_CLIENT_ID}
                        domain={import.meta.env.VITE_AUTH0_DOMAIN}
                    />
                }
                path="/"
            >
                <Route element={<SignupPage />} path="signup" />

                <Route element={<SignupPage />} path="signup/:tag" />

                <Route element={<ChatSupport />} path="/">
                    <Route element={<AuthChecker />} path="/">
                        <Route element={<SimpleLayout />} path="verify">
                            <Route element={<VerifyEmailPage />} path="email" />

                            <Route element={<VerifyAccountPage />} path="account" />

                            <Route path="organization">
                                <Route element={<VerifyOrganizationPage />} index />
                            </Route>
                        </Route>

                        <Route element={<Layout type={currentLayout} />} path="/">
                            <Route
                                element={
                                    <GenerateAsAppliedMapPage onLayoutSwitch={handleLayoutSwitch} />
                                }
                                path="generate"
                            />

                            <Route element={<KmlImportForm />} path="kml-import" />

                            <Route element={<AccountPage />} path="account">
                                <Route element={<Navigate replace to="details" />} index />

                                <Route element={<AccountDetails />} path="details" />

                                <Route element={<></>} path="settings" />

                                <Route element={<LoginPage />} path="login" />

                                <Route element={<LogoutPage />} path="logout" />

                                <Route path="edit">
                                    <Route element={<EditUserDetailsPage />} path="user" />
                                </Route>
                            </Route>

                            <Route path="aam">
                                <Route element={<Navigate to="/generate" />} index />

                                <Route
                                    element={
                                        <AsAppliedMapResultsPage
                                            onLayoutSwitch={handleLayoutSwitch}
                                        />
                                    }
                                    path=":jobId/:mapType"
                                />
                            </Route>

                            <Route element={<UploadFlightLogPage />} path="upload-flight-log" />

                            <Route element={<FlightPage />} path="flight-logs/:flightId" />

                            <Route element={<MapboxTestPage />} path="mapbox-test" />

                            <Route element={<ManageOrganizationPage />} path="organization">
                                <Route element={<Navigate replace to="details" />} index />

                                <Route element={<OrganizationDetails />} path="details" />

                                <Route
                                    element={<OrganizationSubscriptionDetails />}
                                    path="subscription"
                                />

                                <Route element={<TeamDetails />} path="team">
                                    <Route element={<Navigate replace to="active" />} index />

                                    <Route element={<TeamDetails />} path="active" />

                                    <Route element={<TeamDetails />} path="disabled" />

                                    <Route element={<TeamDetails />} path="invited" />
                                </Route>

                                <Route element={<EquipmentDetails />} path="equipment">
                                    <Route element={<Navigate replace to="aircraft" />} index />

                                    <Route element={<AircraftList />} path="aircraft" />

                                    <Route element={<ControllerList />} path="controller" />
                                </Route>
                            </Route>

                            <Route path="organization">
                                <Route path="edit">
                                    <Route
                                        element={<EditAircraftPage />}
                                        path="aircraft/:aircraftId"
                                    />

                                    <Route element={<EditCompanyDetailsPage />} path="company" />

                                    <Route element={<EditPersonOfContactPage />} path="contact" />

                                    <Route
                                        element={<CertificatesAndExemptionsPage />}
                                        path="certifications"
                                    />

                                    <Route
                                        element={<EditControllerPage />}
                                        path="controller/:controllerId"
                                    />
                                </Route>

                                <Route path="new">
                                    <Route element={<NewAircraftPage />} path="aircraft" />

                                    <Route element={<NewControllerPage />} path="controller" />
                                </Route>
                            </Route>

                            <Route path="work-order">
                                <Route element={<WorkOrderPage />} index />

                                <Route path=":workOrderId">
                                    <Route element={<WorkOrderReportPage />} index />

                                    <Route
                                        element={<WorkOrderReportPage startEditable />}
                                        path="edit"
                                    />

                                    <Route
                                        element={<WorkOrderReportPage isDuplicate startEditable />}
                                        path="duplicate"
                                    />

                                    <Route
                                        element={<NewProductUsageReportPage />}
                                        path="product-usage-report/:draftId?"
                                    />
                                </Route>
                            </Route>

                            <Route path="product-usage-report">
                                <Route path=":productUsageReportId">
                                    <Route element={<ProductUsageReportPage />} index />

                                    <Route
                                        element={<NewProductUsageReportPage isEditMode={true} />}
                                        path="edit"
                                    />
                                </Route>
                            </Route>

                            <Route element={<BadPathErrorPage />} path="/*" />
                        </Route>

                        <Route element={<WideLayout />}>
                            <Route element={<HomePage />} index />

                            {MANAGE_CONNECTIONS && (
                                <Route
                                    element={<ManageConnectionsPage />}
                                    path="connections/:code?/:source?"
                                />
                            )}

                            {OPERATOR_MAP_TILE && (
                                <Route element={<OperatorMapPage />} path="/provider-map" />
                            )}

                            <Route element={<FlightLogPage />} path="flight-logs" />

                            <Route element={<MapsListPage />} path="maps" />

                            {FAA_REPORTS && (
                                <Route path="faa-reports">
                                    <Route element={<FaaReportsPage />} index />

                                    <Route
                                        element={<CreateFaaReportPage isEditable={false} />}
                                        path="view/:faaReportId"
                                    />

                                    <Route
                                        element={<CreateFaaReportPage isEditable={true} />}
                                        path="create/:faaReportId"
                                    />
                                </Route>
                            )}

                            <Route path="product-usage-report">
                                <Route element={<ProductUsageReportListPage />} index />

                                <Route
                                    element={<ProductUsageReportListPage newPur={true} />}
                                    path="newPur"
                                />
                            </Route>
                        </Route>
                    </Route>

                    <Route element={<SimpleLayout />} path="/">
                        <Route element={<TermsAndConditionsPage />} path="terms-of-service" />
                    </Route>
                </Route>
            </Route>

            <Route path="auth">
                <Route element={<JohnDeereRedirectPage />} path="johndeere/callback" />

                <Route element={<FieldViewRedirectPage />} path="fieldview/callback" />
            </Route>
        </Routes>
    );
};

export default AppRoutes;
