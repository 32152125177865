import { useCallback, useState } from 'react';

import useFetch from 'hooks/useFetch';

import { ORGANIZATIONS_API } from './constants';
import type { FetchJohnDeereFields } from './types';

const useJohnDeereFields = () => {
    const [isLoading, setIsLoading] = useState(false);
    const { authenticatedGet, handleLoadingAndErrors } = useFetch();

    const fetchJohnDeereFields: FetchJohnDeereFields = useCallback(
        async organizationId => {
            const callback = async () => {
                const { data } = await authenticatedGet(
                    `${ORGANIZATIONS_API}/${organizationId}/fields?`
                );

                return data;
            };

            const response = await handleLoadingAndErrors({
                callback,
                setIsLoading
            });

            return response;
        },
        [authenticatedGet, handleLoadingAndErrors]
    );

    return {
        fetchJohnDeereFields,
        isLoading
    };
};

export default useJohnDeereFields;
