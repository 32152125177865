import { useCallback, useEffect } from 'react';

import useJohnDeere from 'hooks/useJohnDeere';

import type { Props } from './types';

const useData = ({ code, source }: Props) => {
    const {
        authorizeOrganization,
        createJohnDeereConnection,
        deleteJohnDeereConnection,
        fetchJohnDeereOrganizations,
        fetchTokenOrRedirectAndLogIn,
        getJohnDeereConnectionStatus,
        isJohnDeereConnected,
        isLoading: isJohnDeereLoading,
        organizations
    } = useJohnDeere();

    const handleDeleteJohnDeereConnection = useCallback(async () => {
        await deleteJohnDeereConnection();
        code.current = undefined;
        source.current = undefined;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [code, deleteJohnDeereConnection]);

    const fetchJohnDeereConnectionStatus = useCallback(
        async () => await getJohnDeereConnectionStatus(),
        [getJohnDeereConnectionStatus]
    );

    const handleCreateJohnDeereConnectionStatus = useCallback(async () => {
        if (code.current && isJohnDeereConnected === false && source.current === 'johnDeere') {
            await createJohnDeereConnection(code.current);
        }
    }, [code, source, createJohnDeereConnection, isJohnDeereConnected]);

    const handleFetchJohnDeereOrganizations = useCallback(
        async () => await fetchJohnDeereOrganizations(),
        [fetchJohnDeereOrganizations]
    );

    useEffect(() => {
        fetchJohnDeereConnectionStatus();

        window.history.replaceState(null, '', window.location.pathname);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        handleCreateJohnDeereConnectionStatus();

        if (isJohnDeereConnected) {
            handleFetchJohnDeereOrganizations();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isJohnDeereConnected]);

    return {
        authorizeOrganization,
        fetchJohnDeereConnectionStatus,
        fetchJohnDeereOrganizations,
        fetchTokenOrRedirectAndLogIn,
        handleDeleteJohnDeereConnection,
        handleFetchJohnDeereOrganizations,
        isJohnDeereConnected,
        isJohnDeereLoading,
        organizations: organizations.current || []
    };
};

export default useData;
