import { useCallback, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { HorizontalContainer, PrimaryButton } from '@rantizo-software/rantizo-ui/';

import ConnectionListItem from 'components/ConnectionListItem';
import DestructiveActionButton from 'components/DestructiveActionButton';
import DisconnectConfirmationModal from 'components/DisconnectConfirmationModal';
import ListText from 'components/ListText';
import SmallText from 'components/SmallText';
import FieldViewIcon from 'components/icons/FieldViewIcon';

import useData from './hooks/useData';
import useTranslation from './hooks/useTranslation';

import { CODE, MANAGE_CONNECTIONS_PAGE, SOURCE, TEST_ID, UNDEFINED } from './constants';
import type { Props } from './types';

import styles from './styles.module.scss';

const FieldViewConnectionListItem = ({ testId = TEST_ID }: Props) => {
    const [disconnectConfirmationModal, setDisconnectConfirmationModal] = useState(false);
    const { CONNECT, DISCONNECT, FIELD_VIEW_DESCRIPTION, FIELD_VIEW_TITLE } = useTranslation();

    const [searchParams] = useSearchParams();

    const code = useRef(searchParams.get(CODE) === UNDEFINED ? undefined : searchParams.get(CODE));
    const source = useRef(
        searchParams.get(SOURCE) === UNDEFINED ? undefined : searchParams.get(SOURCE)
    );

    const {
        fetchTokenOrRedirectAndLogIn,
        handleDeleteFieldViewConnection,
        isFieldViewConnected,
        isFieldViewLoading
    } = useData({ code, source });

    const handleCloseConfirmationModal = useCallback(() => {
        setDisconnectConfirmationModal(false);
    }, []);

    return (
        <ConnectionListItem testId={testId}>
            {disconnectConfirmationModal && (
                <DisconnectConfirmationModal
                    onClose={handleCloseConfirmationModal}
                    onConfirm={handleDeleteFieldViewConnection}
                />
            )}

            <FieldViewIcon />

            <HorizontalContainer className={styles.nestedText}>
                <ListText className={styles.listText} text={FIELD_VIEW_TITLE} />

                <SmallText text={FIELD_VIEW_DESCRIPTION} />
            </HorizontalContainer>

            {isFieldViewConnected ? (
                <DestructiveActionButton
                    isLoading={isFieldViewLoading}
                    onClick={() => setDisconnectConfirmationModal(true)}
                    text={DISCONNECT}
                />
            ) : (
                <PrimaryButton
                    className={styles.primaryButton}
                    isLoading={isFieldViewLoading}
                    onClick={() => fetchTokenOrRedirectAndLogIn(MANAGE_CONNECTIONS_PAGE)}
                    text={CONNECT}
                />
            )}
        </ConnectionListItem>
    );
};

export default FieldViewConnectionListItem;
