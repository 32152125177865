import { useCallback, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';

import CompanyDetailsForm from 'components/CompanyDetailsForm';

import useCurrentOrganization from 'hooks/useCurrentOrganization';
import usePageRoutes from 'hooks/usePageRoutes';

import useData from './hooks/useData';
import useTranslation from './hooks/useTranslation';

import { TEST_ID } from './constants';
import type { FunctionComponent, OrganizationRequest, Props } from './types';

const EditCompanyDetailsPage: FunctionComponent<Props> = ({ testId = TEST_ID }) => {
    const { currentOrganization } = useCurrentOrganization();
    const { saveToServer } = useData();
    const navigate = useNavigate();
    const { BUTTON, TITLE } = useTranslation();

    const { accountLoginPage, manageOrganizationPage } = usePageRoutes();

    const [loading, setLoading] = useState(false);

    const onBack = useCallback(() => {
        navigate(manageOrganizationPage);
    }, [manageOrganizationPage, navigate]);

    const handleSubmit = useCallback(
        async (values: OrganizationRequest) => {
            const response = await saveToServer(values);

            if (response?.data) {
                onBack();
            }
        },
        [onBack, saveToServer]
    );

    const onSubmit = useCallback(
        async (values: OrganizationRequest) => {
            setLoading(true);

            await handleSubmit(values);

            setLoading(false);
        },
        [handleSubmit]
    );

    if (!currentOrganization) {
        return <Navigate replace to={accountLoginPage} />;
    }

    const {
        address1,
        address2,
        city,
        coa,
        faaDocketNumber,
        faaExemptionNumber,
        name,
        phoneNumber,
        state,
        zipCode
    } = currentOrganization;

    const initialValues: OrganizationRequest = {
        coa: coa,
        companyName: name,
        faaDocketNumber: faaDocketNumber ?? null,
        faaExemptionNumber: faaExemptionNumber ?? null,
        location: {
            address1: address1 ?? null,
            address2: address2 ?? null,
            city: city ?? null,
            state: state ?? null,
            zipCode: zipCode ?? null
        },
        phone: phoneNumber ?? null
    };

    return (
        <CompanyDetailsForm
            buttonText={BUTTON}
            initialValues={initialValues}
            isLoading={loading}
            isOnboarding={false}
            onBack={onBack}
            onSubmit={onSubmit}
            testId={testId}
            title={TITLE}
        />
    );
};

export default EditCompanyDetailsPage;
